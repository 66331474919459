/* ----------------------------- section-mvp ----------------------------- */
.section-mvp {
  .section-mvp__card-mvp {
    width: 100%;
  }

  .section-mvp__button-wrapper {
    display: flex;
    flex-direction: row;
    gap: 10px;
    padding: 20px 10px 40px 10px;
  }

  .section-mvp__button {
  }
}

/* ----------------------------- section-step ----------------------------- */
.section-step {
  .section-step__container {
    max-width: 900px;
    width: 100%;
    flex-wrap: wrap;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    gap: 16px;
    margin-bottom: 40px;
    text-align: center;

    &.section-step__container--fixed-height {
      height: 180px;

      @include respond-to("sm") {
        height: 0;
      }
    }

    &.section-step__container__col {
      margin-top: 0;
      margin-bottom: 0;
      align-items: start;
      justify-content: flex-start;
      flex-direction: row;
    }
  }

  .mobile-container {
    @media all and (max-width: 768px) {
      display: flex;
      justify-content: flex-start;
      align-content: flex-start;
      text-align: left;
    }
  }

  .section-step__container--no-data {
    background-color: $white;
  }

  .section-step__title {
    margin-bottom: 16px;

    text-align: center;

    @include respond-to("sm") {
      display: none;
    }
  }

  .section-step__description {
    //margin-bottom: 32px;

    text-align: center;
    height: 26px;

    @include respond-to("sm") {
      display: none;
    }
  }

  .section-step__wrapper {
    display: flex;
    flex-direction: column;
    gap: 32px;

    @include respond-to("sm") {
      gap: 15px;
    }
  }

  .section-step__row {
  }

  .section-step__col-2 {
    display: flex;
    flex-direction: row;
    gap: 32px;

    @include respond-to("sm") {
      flex-direction: column;
      gap: 15px;
    }
  }

  .section-step__col-2--col {
    @include respond-to("md") {
      flex-direction: column;
      gap: 15px;
    }
  }

  .section-step__btn {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 16px;
    margin-bottom: 35px;

    @include respond-to("sm") {
      margin-bottom: 20px;
    }
  }

  .section-step__btn-grey {
  }

  .section-step__btn-blue {
    @include respond-to("sm") {
      width: 100%;
    }
  }

  .section-step__info {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 15px;
    width: 100%;
    background-color: $blue;
    color: $blueDeep;
    padding: 15px;
    border-radius: 4px;

    @include respond-to("md") {
      flex-direction: column;
      margin-bottom: 30px;
    }
    .section-step__info--col {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      width: 100%;
    }
  }
}

/* ----------------------------- section-profile ----------------------------- */
.section-profile {
  .section-profile__container {
  }

  .section-profile__title {
    margin-bottom: 24px;

    text-align: center;
  }

  .section-profile__description {
    margin-bottom: 40px;

    text-align: center;
  }

  .section-profile__wrapper {
  }

  .section-profile__form-group {
    display: flex;
    flex-direction: column;
    gap: 32px;
  }

  .section-profile__row {
  }

  .section-profile__btn {
    width: 100%;
    margin-top: 40px;
  }
}

.section-realty-images__tips {
  //position: absolute;
  //top: 332px;
  //right: 40px;
  width: 165px;
  padding-bottom: 10px;
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  border-left: 1px solid $greyMetal;
  height: fit-content;
  text-align: start;

  @media all and (max-width: 1300px) {
    width: 138px;
  }

  @media all and (max-width: 1250px) and (min-width: 1200px) {
    width: 99px;
  }

  &.section-realty-images__tips--static {
    position: static !important;
    width: 258px !important;

    @media all and (max-width: 1300px) {
      width: 258px !important;
    }

    @include respond-to("sm") {
      width: 140px;
    }
  }
  @include respond-to("sm") {
    position: relative;
    top: 0;
    left: 0;
  }

  .section-realty-images__tips-header {
    margin-left: 16px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }

  .section-realty-images__tips-icon {
    ::ng-deep mat-icon {
      width: 24px;
      height: 24px;
    }
  }

  .section-realty-images__tips-description {
    margin-left: 16px;
    width: 140px;

    @media all and (max-width: 1300px) {
      width: 138px;
    }

    &.section-realty-images__tips-description--modal {
      width: 257px;

      @include respond-to("sm") {
        width: 200px;
      }
    }

    a {
      color: #2d00f7;
      font-family: "Plus Jakarta Sans", sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      text-decoration-line: underline;
    }
  }
}

.section-realty-images__formats {
  text-align: center;
  top: 160px;
  right: 40px;
  color: $greyMetal;
  margin-bottom: 20px;
}

.section-realty-images {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 15px;

  &.section-realty-images--reverse {
    flex-direction: row-reverse;
  }

  @include respond-to("md") {
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;

    &::-webkit-scrollbar-thumb {
      background-color: $greyMetal;
      border: 1px solid rgba($white, 0.1);
    }

    &::-webkit-scrollbar-track {
      border-radius: 4px;
      background-color: $greySoft;
    }
  }

  .section-realty-images__mobile-header {
    @include respond-to("md") {
      display: flex;
      flex-direction: row;
      gap: 20px;
    }
  }

  .section-realty-images__container {
    display: flex;
    flex-wrap: wrap;
    gap: 40px;
    margin-bottom: 40px;

    max-width: 100%;
    min-height: 60px;
    cursor: pointer;
    overflow-y: auto;
    overflow-x: hidden;
    width: 100%;
    height: 50vh;
    &.section-realty-images__container--large {
      height: 56vh;
    }

    @include respond-to("sm") {
      padding-right: 20px;
      height: 40vh;
    }

    &::-webkit-scrollbar {
      width: 4px;

      @include respond-to("430px") {
        width: 4px;
      }
    }

    &::-webkit-scrollbar-thumb {
      background-color: $greyMetal;
      border: 1px solid rgba($white, 0.1);
    }

    &::-webkit-scrollbar-track {
      border-radius: 4px;
      background-color: $greySoft;
    }

    .section-realty-images__loader {
      position: absolute;
      z-index: 100;
      display: flex;
      align-items: center;
      justify-content: center;
      top: 50%;
      left: 30%;
    }
  }

  .section-realty-images__container > * {
    flex: 1 1 273px;
    max-width: 273px;

    @media all and (max-width: 1200px) {
      flex: 1 1 300px;
      max-width: 300px;
    }

    @include respond-to("md") {
      flex: 1 1 320px;
      max-width: 320px;
    }
  }

  .section-realty-images__btns {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 16px;

    @include respond-to("md") {
      justify-content: center;
      padding: 20px 21px;
      align-items: center;
    }
  }
}

.section-expose {
  .section-expose__language {
    position: absolute;
    right: 40px;
    top: 40px;

    @include respond-to("md") {
      top: 11px;
      right: 20px;
    }
  }

  .section-expose__card-expose {
    position: fixed;
    width: 100%;

    &.section-expose__card-expose--with-sidebar {
      width: calc(90vw - 160px);
      //position: fixed;
      position: relative;
      @include respond-to("md") {
        width: 100%;
      }
    }

    &.section-expose__card-expose--with-opened-sidebar {
      //width: calc(90vw - 300px);
      //width: calc(90vw - 223px);
      width: 100%;
      @include respond-to("md") {
        width: 100%;
      }
    }
  }
}

.section-settings {
  .section-settings__card-expose {
    width: 100%;
  }
}

.summary-page__section {
  .summary-page__header-data {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    &.summary-page__header-data--reversed {
      //flex-direction: row-reverse;
    }

    @include respond-to("sm") {
      align-items: baseline;
      justify-content: center;
    }

    @media all and (min-width: 481px) and (max-width: 768px) {
      justify-content: inherit;
    }

    @include respond-to("md") {
      align-items: baseline;
      // justify-content: center;
    }
  }

  .summary-page__header-element {
    margin: 20px;

    &.summary-page__header-element__col {
      margin: 10px;
    }

    .document-container__text {
    }
  }
}
//----------------document page section -------------//
$document-height: 806px;
$document-width: 567px;
$document-width--mobile: 320px;

@media all and (max-width: 480px) {
  $document-height: 100vh;
}

.document-page__section-max-width {
  width: 100vw;
  height: 100vh;
}

.document-page__section {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  //align-items: flex-start;
  margin-top: 40px;
  //margin-bottom: 100px;
  height: $document-height;
  //width: calc(63vw - 40px);
  //width: calc(100vw - 586px);

  @media all and (min-width: 481px) and (max-width: 768px) {
    height: auto;
    min-height: 580px;
  }

  @include respond-to("sm") {
    flex-direction: column;
    align-items: center;
    height: auto;
    margin-bottom: 0;
  }

  .document-page__image-container {
    position: relative;
    display: flex;
    //align-items: center;
    //justify-content: center;
    flex-direction: column;
    width: $document-width;
    background-color: $backgroundDoc;
    //height: 806px;
    //max-height: 100vh;
    //margin-bottom: 40px;

    @include respond-to("sm") {
      //width: 100vw;
    }

    &.document-page__image-container--with-gap {
      gap: 30px;
    }

    &.document-page__plan-img {
      padding: 40px 50px;
      background-color: $white;
    }
  }
  //.document-page__address-img-container {
  .document-page__address-image {
    height: 333px;
    min-width: 224px;

    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    //width: 50%;
    @include respond-to("sm") {
      height: 60%;
    }
  }

  .document-page__address-image {
    height: 333px;
    width: 239px;

    background-repeat: no-repeat;
    //width: 50%;
    @include respond-to("sm") {
      // height: 60%;
    }
  }

  .document-page__image-background {
    height: 720px;
    width: 487px;
    background-repeat: no-repeat;
    background-position: center;
    overflow: hidden;
    background-size: cover;
    //@include respond-to('md') {
    //  width: 400px;
    //}

    @media all and (min-width: 481px) and (max-width: 768px) {
      width: 100%;
    }

    &.document-page__image-background--big {
      height: $document-height;
      width: $document-width;
      background-size: cover;

      @include respond-to("sm") {
        //width: $document-width--mobile;
      }
    }

    .document-page__image-background--right {
      background-position: -$document-width 0;
      background-size: cover;

      width: 1300px;
      height: $document-height;

      @include respond-to("sm") {
        width: 600px;
        background-position: -$document-width--mobile 0;
      }
    }
    .document-page__image-background--left {
      background-position: 0 0;
      background-size: cover;

      width: 1300px;
      height: $document-height;

      @include respond-to("sm") {
        width: 600px;
      }
    }
  }

  .document-page__image-background-small {
    height: 100%;
    width: 487px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    &.document-page__image-background-small--top {
      width: 535px;
      //height: 384px;

      @media all and (min-width: 481px) and (max-width: 768px) {
        width: 567px;
      }
    }

    &.document-page__image-background-small-1 {
      height: 240px;
    }

    @include respond-to("md") {
      width: 400px;
      height: 30vh;
    }
    @include respond-to("sm") {
      width: 300px;
      //height: auto;
      height: 20vh;
      //height: 40vh;
    }

    //   @media all and (min-width: 481px) and (max-width: 768px){
        //   width: 41vh;
    //   }
  }

  .document-page__address {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 487px;
    height: 50vh;
    margin-top: 20px;

    //@include respond-to('md') {
    //  width: 400px;
    //}

    &.document-page__address-centered {
      display: flex;
      flex-direction: column;
      gap: 10px;
      margin-top: 20px;
      justify-content: center;
      align-items: center;
    }

    @include respond-to("sm") {
      width: 335px;
    }
  }

  .document-page__personal-info {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    //align-items: center;
  }

  .document-page__image-container-inner {
    padding: 50px 30px 30px;
    background-color: $white;
    display: flex;
    flex-direction: row;
    gap: 40px;
    height: $document-height;
    //height: 100vh;

    //height: auto;
    width: $document-width;
    position: relative;
    //margin-bottom: 40px;

    @include respond-to("sm") {
      flex-direction: column;
      align-items: center;
      //max-width: 65%;
      width: 100%;
      padding: 20px;
      height: auto;
      min-height: 580px;
    }

    @media all and (min-width: 481px) and (max-width: 768px) {
      //height: auto;
    }
  }

  .document-page__page-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 30px;

    @media all and (max-width: 480px) {
      align-items: center;
    }

    .document-page__image-info {
      position: relative;
      height: 35vh;

      @include respond-to("sm") {
        height: 20vh;
      }

      .document-page__image-label {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 109px;
        height: 52px;
        background-color: $blueRich;
        //padding: 16px 17px;
        color: $white;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 10px;
      }
    }
  }

  //.document-page__image-container {
  .document-page__image {
    height: 100%;
    width: calc(100vw - 40px);
    max-width: 527px;
    max-height: 372px;
    object-fit: cover;

    @include respond-to("lg") {
      width: calc(100vw - 20px);
    }
  }
  //}

  .document-page__image-edit {
    z-index: 50 !important;
    position: absolute;
    width: $document-width;
    display: flex;
    justify-content: center;
    align-items: center;
    height: $document-height;
    left: 0;
    top: 0;
    //@include respond-to('lg') {
    //  width: 400px;
    //}
    @include respond-to("sm") {
      width: 100%;
    }

    &.active {
      background-color: rgba(117, 119, 128, 0.4);

      .document-page__edit-icon {
        display: flex;
      }
    }
    &:hover {
      background-color: rgba(117, 119, 128, 0.4);

      .document-page__edit-icon {
        display: flex;
      }
    }

    &.document-page__image-edit-small {
      height: 100%;
      width: 100%;

      @include respond-to("sm") {
        width: 100%;
      }
    }
  }

  .document-page__text-container-first,
  .document-page__text-container-left {
    display: flex;
    position: absolute;
    left: 0px;
    bottom: 0;
    background-color: rgba(117, 119, 128, 0.5);
    cursor: pointer;
    height: $document-height;
    width: $document-width;
    justify-content: flex-end;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 30px;
    padding-bottom: 60px;

    //@include respond-to('lg') {
    //  width: 400px;
    //}

    @media all and (max-width: 480px) {
      width: 100%;
      padding-left: 0;
      align-items: center;
    }
  }

  .document-page__text-container-left {
    justify-content: flex-start;
    padding-top: 100px;
  }
  .document-page__text-container-total {
    display: block;
    position: absolute;
    left: 32px;
    //bottom: 54px;
    background-color: rgba(#757780, 0.5);
    cursor: pointer;

    @include respond-to("lg") {
      left: 32px;
      //bottom: 71px;
      //max-width: 92%;
    }

    &.document-page__text-container-total--relative {
      position: relative;
      background-color: transparent;
      left: 0;
      //margin-top: 20px;
    }

    &.document-page__text-container-total--relative-2 {
      position: relative;
      background-color: transparent;
      left: 0;
      margin-top: 20px;

      @media all and (min-width: 481px) and (max-width: 768px) {
        margin: 0 auto;
      }

      @media all and (max-width: 480px) {
        margin: 0 auto;
        padding: 5px 0;
      }
    }

    &.document-page__text-container-total--grey {
      background-color: $secondaryDoc;
      padding: 30px;
    }

    .document-page__regenerate-text {
      z-index: 20000;
      transition: 0.8s;
      transition-delay: 0.5s;
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      padding: 8px;
      justify-content: center;
      align-items: center;
      gap: 8px;

      color: $blackRich;
      font-family: Plus Jakarta Sans;
      font-size: 13px;
      font-style: normal;
      font-weight: 600;
      line-height: 18px;

      margin-top: -50px;
      background: $whiteSnow;
      border-radius: 4px;

      &.disabled {
        pointer-events: none;
        opacity: 0.7;
      }
    }
  }

  .document-page__image--title {
    width: $document-width;
    //height: 806px;
    height: calc($document-height - 40px);
    object-fit: cover;

    &.document-page__image--title-plan {
      height: calc($document-height - 150px);
      width: 418px;
    }

    @include respond-to("lg") {
      width: 400px;
      height: 600px;
    }

    @include respond-to("sm") {
      width: 100vw;
      max-height: $document-height;
    }
  }

  .document-page__text-container {
    padding-right: 40px;
    //padding-bottom: 40px;

    &.document-page__text-container--left {
      padding-left: 40px;
    }
  }

  .document-page__page-number {
    position: absolute;
    right: 30px;
    bottom: 30px;

    color: $greyMetal;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;

    @media all and (max-width: 480px) {
      right: 125px;
    }
  }
  .document-page__page-number--left {
    position: absolute;
    left: 30px;
    bottom: 30px;

    color: $greyMetal;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;

    @include respond-to("sm") {
      left: 125px;
    }
  }

  .document-page__text {
    max-width: 487px;
    color: $blackRich !important;
    text-align: start;

    @include respond-to("sm") {
      //max-width: calc(100% - 40px);
      //min-width: 320px;
      width: 100%;
      height: auto;
      padding-left: 20px;
    }
  }

  .document-page__text-for-mob {
    max-width: 487px;
    color: $blackRich !important;
    text-align: start;

    @include respond-to("sm") {
      width: 100%;
      height: auto;
      padding-left: 20px;
      max-width: fit-content;
      margin: 0 auto;
    }
  }

  .document-page__text--plain {
    margin-bottom: 40px;

    @include respond-to("sm") {
      font-size: 14px !important;
      line-height: 24px !important;
    }
  }

  .document-page__text-title__32 {
    @include respond-to("md") {
      font-size: 26.6px;
      line-height: 35.5px;
    }
  }

  .document-page__text-title {
    margin-bottom: 24px;
    font-size: 20px !important;
    font-style: normal;
    font-weight: 600;
    //line-height: 44px !important;
  }

  .document-page__regenerate-border {
    &:hover {
      border: 1px solid $blueTotal;
    }
  }

  .menu-text {
    font-family: "Plus Jakarta Sans", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    margin-bottom: 15px;

    &.menu-text__index {
      font-weight: 600;
    }
  }

  .document-page__text--title {
    color: $whiteSnow;
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: 56px;

    @include respond-to("lg") {
      font-size: 26px;
      line-height: 35px;
    }
  }
}

.document-page__second-title {
  color: $whiteSnow;
}

.section-step__container--with-sidebar {
  display: flex;
  flex-wrap: wrap;
  max-height: 80vh;
  overflow-y: scroll;

  &::-webkit-scrollbar-thumb {
    display: none;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  .document-page__loader {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    width: 100vw;
    height: 100vh;
    left: 12%;
    background-color: rgba($white, 0.2);
    z-index: 100;
    @include respond-to('md') {
      left: 0;
    }
  }
}

.section-step__container--with-sidebar > .document-page__section {
  flex: 1 1 $document-width;

  @include respond-to("sm") {
    flex: 1 1 $document-width--mobile;
  }
}

.section-step__container--with-sidebar > .document-page__section--first-page {
  flex: 1 1 1214px;

  @include respond-to("sm") {
    flex: 1 1 $document-width--mobile;
  }
}

.document-page__section--column {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.document-page__section--centered {
  justify-content: center;
}

.document-page__section--left {
  justify-content: flex-start;
}

.document-page__section--left {
  justify-content: flex-end;
}

.document-page__section--first-page {
  //width: calc(63vw - 40px);
}

/* ----------------------------- section-generating ----------------------------- */
.section-generating {
  .section-generating__title {
    color: var(--neutrals-rich-black, #001011);
    text-align: center;
    font-family: Plus Jakarta Sans;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 48px;
    padding-bottom: 20px;
  }

  .section-generating__text {
    color: var(--neutrals-metal-grey, #b7b8be);
    text-align: center;
    font-variant-numeric: lining-nums proportional-nums;
    font-family: Plus Jakarta Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px;
  }
}

.loader-percent {
  color: var(--neutrals-rich-black, #001011);
  text-align: center;
  font-family: Plus Jakarta Sans;
  font-size: 70px;
  font-style: normal;
  font-weight: 500;
  line-height: 90px;
}

.loader-time {
  color: var(--neutrals-sonic-silver, #757780);
  text-align: center;
  font-family: Plus Jakarta Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.section-generating__content {
  padding-top: 140px;
  padding-bottom: 200px;
}

.expose-functional {
  margin-top: -222px;
}

/* ------------------------------- section-onboarding ------------------------------- */
.section-onboarding {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 40px;

  @include respond-to("md") {
    justify-content: center;
  }

  .section-onboarding__language {
    position: absolute;
    right: 40px;
    top: 40px;

    @include respond-to("md") {
      top: 11px;
      right: 20px;
    }
  }

  .section-onboarding__container {
    display: flex;
    flex-direction: column;
    gap: 40px;
    max-width: 740px;
    width: 100%;
    margin: auto;
    padding: 20px;

    @include respond-to("md") {
      gap: 25px;
      padding-top: 60px;
    }
  }

  .section-onboarding__title {
    @include respond-to("md") {
      font-size: 20px;
      line-height: 30px;
    }
  }

  .section-onboarding__description {
    margin-top: 24px;

    @include respond-to("md") {
      font-size: 14px;
      margin-top: 5px;
    }
  }

  .section-onboarding__content {
  }

  a {
    text-decoration-line: underline;

    color: $blueTotal;

    cursor: pointer;
  }

  .section-onboarding__image-container {
    position: relative;

    max-width: 624px;
    width: 100%;
    min-height: 100vh;

    @include respond-to("md") {
      display: none;
    }

    img {
      max-width: 624px;
      width: 100%;
      height: 100%;

      object-fit: cover;
    }
  }

  .section-onboarding__logo {
    position: absolute;
    top: 40px;
    left: 40px;

    font-family: "Plus Jakarta Sans", sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
    text-decoration-line: none;

    color: $whiteSnow;
  }

  .section-onboarding__wrapper {
    display: flex;
    flex-direction: column;
    gap: 32px;

    @include respond-to("sm") {
      gap: 15px;
    }
  }

  .section-onboarding__info {
    @include respond-to("sm") {
      font-size: 12px;
    }

    &.checkbox {
      cursor: pointer;
    }
  }

  .section-onboarding__message {
    text-align: center;

    @include respond-to("md") {
      font-size: 20px;
      line-height: 30px;
    }
  }

  .section-onboarding__privacy {
    display: flex;
    flex-direction: row;
    gap: 10px;
  }

  .section-onboarding__checkbox {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    margin-top: 6px;

    border-radius: 2px;
    border: 1.3px solid $blueTotal;

    transition: all 0.2s ease-in-out;
    cursor: pointer;
  }

  .section-onboarding__icon {
    display: block;
    opacity: 0;
    user-select: none;

    transition: all 0.2s ease-in-out;
  }

  .section-onboarding__active {
    border: 2px solid $blueTotal;

    transition: all 0.2s ease-in-out;

    img {
      opacity: 1;
    }
  }
}

.summary-line {
  max-width: 900px;
  width: 100%;
  height: 1px;
  background: #ebedef;
}

.summary-page_line {
  max-width: 900px;
  width: 100%;
  height: 1px;
  background: #ebedef;

  @include respond-to("sm") {
    max-width: 900px;
    width: 100%;
    height: 1px;
    background: #ebedef;
    align-items: flex-start;
    align-content: flex-start;
    gap: 20px;
    flex-wrap: wrap;
    display: flex;
    z-index: 3;
    position: absolute;
    left: 0;
  }

  @include respond-to("md") {
    max-width: 900px;
    width: 100%;
    height: 1px;
    background: #ebedef;
    align-items: flex-start;
    align-content: flex-start;
    gap: 20px;
    flex-wrap: wrap;
    display: flex;
    z-index: 3;
    position: absolute;
    left: 0;
  }
}

.summary-page_items {
  display: flex;
  flex-direction: column;
  gap: 20px;

  &.summary-page_items--doc {
    gap: 20px;
    display: flex;
    flex-direction: column;
  }

  &.summary-page_items__col {
    flex-direction: column;
    gap: 0;
    display: grid;
    place-content: center;
  }

  .summary-page_items-title {
    color: $textDoc;
  }

  @include respond-to("md") {
    flex-direction: column;
    gap: 0;
    display: grid;
    place-content: center;
  }
}

.summary-page_row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @include respond-to("sm") {
  }
}

.summary-page_row-doc {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @include respond-to("sm") {
    flex-direction: row;
    gap: 0;
    // width: 100vh;
  }
}

.summary-page_column {
  display: flex;
  flex-direction: column;
  &.summary-page_column--doc {
    width: auto;

    @include respond-to("md") {
      max-width: 240px;
    }
  }
}

.summary-page_gen {
  display: flex;
  justify-content: center;
}

.summary-page_hashtag-row {
  display: flex;
  flex-direction: row;
  padding: 24px 0 40px;
  gap: 8px;

  &.summary-page_hashtag-row__col {
    flex-wrap: wrap;
    padding: 10px 0 10px;
  }

  @include respond-to("md") {
    flex-wrap: wrap;
  }
}

.summary-page_hashtag-date {
  display: flex;
  padding: 8px;
  align-items: center;
  border-radius: 4px;
  background: var(--neutrals-soft-grey, #ebedef);
}

.name-center {
  display: flex;
  justify-content: center;
}

.summary-page_center {
  display: flex;
  justify-content: center;

  @include respond-to("sm") {
    justify-content: flex-start;
    display: flex;
  }

  @include respond-to("md") {
    justify-content: flex-start;
    display: flex;
  }
}

.summary-page_gap {
  gap: 100px;

  &.summary-page_gap--doc {
    gap: 0;
  }

  @include respond-to("sm") {
    gap: 0px;
  }

  @include respond-to("md") {
    gap: 0px;
  }
}

.price-row {
  @include respond-to("sm") {
    display: flex;
    flex-direction: column;
  }

  @include respond-to("md") {
    display: flex;
    flex-direction: column;
  }
}

.summary-page_price-column {
  display: contents;

  &.summary-page_price-column__col {
    display: flex;
    flex-direction: column;
  }

  @media all and (max-width: 768px) {
    display: flex;
    flex-direction: column;
  }
}

.summary-page_price-column-reverse {
  display: contents;

  &.summary-page_price-column-reverse__col {
    display: flex;
    flex-direction: column-reverse;
  }

  @media all and (max-width: 768px) {
    display: flex;
    //flex-direction: column-reverse;
  }
}

.summary-page_hidden-line {
  @include respond-to("sm") {
    display: none;
  }

  @include respond-to("md") {
    display: none;
  }
}

.summary-step__title {
  text-align: center;
}

.summary-page_price {
  color: var(--neutrals-rich-black, #001011);
  text-align: center;

  font-family: Plus Jakarta Sans;
  font-size: 26px;
  font-style: normal;
  font-weight: 500;
  line-height: 36px;
  display: flex;
  justify-content: center;
  margin-bottom: 8px;

  &.summary-page_price__col {
    font-size: 18px;
    line-height: 20px;
  }

  @include respond-to("sm") {
    justify-content: flex-start;
    display: flex;
  }

  @include respond-to("md") {
    justify-content: flex-start;
    display: flex;
  }
}

.summary-page_mb-title {
  display: block;

  &.summary-page_mb-title__col {
    font-size: 26px;
    line-height: 36px;
  }
}

.summary-page_item--info {
  color: var(--neutrals-rich-black, #001011);
  font-variant-numeric: lining-nums proportional-nums;

  font-family: Plus Jakarta Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
  padding-bottom: 16px;
  display: flex;
  //width: auto;
  width: 280px;


  .summary-page_item--info-fixed {
    width: 160px;

    @include respond-to("sm") {
      width: 150px;
    }
  }


  @include respond-to("sm") {
    width: 100vh;
  }

  @include respond-to("sm") {
    // width: auto;
    //flex-direction: column;
    align-items: flex-start;
  }
}

.summary-page_item--info-in-doc {
  font-size: 12px !important;
  line-height: 12px  !important;
}

.summary-page_item {
  color: var(--neutrals-sonic-silver, #757780);
  font-variant-numeric: lining-nums proportional-nums;
  font-weight: 500;
  width: 230px;
}

.summary-page_hashtag-text {
  color: var(--neutrals-sonic-silver, #757780);
  font-family: Plus Jakarta Sans;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
}

/* ----------------------------- section setting-page ----------------------------- */
.setting-page {
  .setting-page__container {
    margin-left: 300px;
    padding: 10px 40px;

    @include respond-to("md") {
      margin-left: 0;
      padding: 10px 20px;
    }
  }

  .setting-page__title {
    padding-bottom: 40px;

    @include respond-to("md") {
      display: none;
    }
  }

  .setting-page__cards {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 40px;

    @include respond-to("1140px") {
      gap: 30px;
    }

    @include respond-to("1050px") {
      gap: 20px;
    }

    @include respond-to("1020px") {
      gap: 10px;
    }

    @include respond-to("md") {
      justify-content: center;
    }
  }

  .setting-page__card {
    max-width: 355px;
    width: 100%;
    height: auto;

    @include respond-to("1140px") {
      max-width: 320px;
    }

    @include respond-to("1050px") {
      max-width: 310px;
    }

    @include respond-to("1010px") {
      max-width: 290px;
    }

    @include respond-to("630px") {
      max-width: 355px;
    }
  }
}

/* ------------------------------ Base sections ----------------------------- */

.header-section {
  margin-bottom: 40px;
  .header-section__title {
    font-size: 40px;
    font-weight: 600;
    line-height: normal;
  }
  @include respond-to("md") {
    display: none;
  }
  @include respond-to("sm") {
    margin-bottom: 0;
  }

  &.header-section__shown {
    @include respond-to("md") {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }
  }
}

/* ------------------------------ profile ----------------------------- */

.profile {
  display: flex;
  flex-direction: row;
  gap: 40px;

  @include respond-to("900px") {
    flex-direction: column;
    gap: 32px;
  }

  &__col-left {
    max-width: 260px;
    width: 100%;

    @include respond-to("970px") {
      max-width: 200px;
    }
  }

  &__col-right {
    max-width: 800px;
    width: 100%;
  }

  &__form {
  }

  &__subtitle {
    margin-bottom: 4px;
  }

  &__description {
  }

  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    margin-bottom: 32px;

    @include respond-to("xl") {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &__user {
    display: flex;
    align-items: center;
    gap: 16px;
  }

  &__img {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 80px;
    min-height: 80px;

    font-size: 26px;
    font-style: normal;
    font-weight: 500;
    line-height: 36px;

    background-color: $greySoft;
    border-radius: 50%;

    img {
      width: 80px;
      height: 80px;

      border-radius: 50%;
      object-fit: cover;
    }
  }

  &__content {
    max-width: 340px;
    //width: 100%;

    word-break: break-all;
  }

  &__title {
  }

  &__btn-group {
    display: flex;
    gap: 8px;
    max-width: 265px;
    width: 100%;
  }

  &__btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: auto;
    height: 44px;
    padding: 10px 16px;

    font-family: "Plus Jakarta Sans", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;

    color: $whiteSnow;
    background-color: $blueTotal;
    border: none;
    border-radius: 4px;
    outline: none;
    cursor: pointer;

    transition: all 0.2s ease-in-out;

    &:hover {
      background-color: $blueDeep;
    }
  }

  &__btn--ghost {
    color: $blackRich;
    background-color: transparent;
    border: 1px solid $greySoft;

    &:hover {
      background-color: $greySoft;
    }

    &:disabled {
      color: $greyMetal;
      pointer-events: none;
    }
  }

  &__upload {
  }

  &__hidden {
    display: none;
  }
}

.document-page__map-text {
  width: 238px;
  color: $accentDoc !important;

  @include respond-to("sm") {
    width: auto;
    justify-content: center;
    padding-right: 28px;
  }
}

/* ------------------------------ profile-list ----------------------------- */

.profile-list {
  display: flex;
  flex-direction: column;
  gap: 80px;

  @include respond-to("900px") {
    gap: 40px;
  }
}

.doc-userprofile-name {
  color: #001011;
  font-family: var(--fontDoc), sans-serif;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
}

.doc-mob-settings {
  max-width: 350px;
  width: 100%;
}

.map-doc-subtitle {
  position: absolute;
  bottom: 20px;
  right: 0;

  @media all and (max-width: 768px) {
    position: relative;
    bottom: auto;
    right: auto;
    margin-top: 10px;
  }
}

.height-for-mob-version {
  padding: 40px;
  background-color: $backgroundDoc;
  display: flex;
  flex-direction: row;
  gap: 40px;
  height: $document-height;
  max-width: $document-width;
  position: relative;

  @include respond-to("sm") {
    flex-direction: column;
    align-items: center;
    //max-width: 65%;
    min-width: 300px;
    padding: 20px;
    height: 100vh;
  }

  @media all and (min-width: 481px) and (max-width: 768px) {
    height: 100%;
    min-height: 806px;
  }
}

.text-castom-padding-settings {
  @media all and (min-width: 481px) and (max-width: 768px) {
    padding: 10px 0;
  }
}

.document-page-width-100 {
  width: 100%;

  @media all and (max-width: 480px) {
    width: auto;
  }
}

.width-for-document-company-info {
  @media all and (max-width: 480px) {
    width: 360px;
  }
}

.document-page--map {
  display: flex;
  justify-content: center;
  align-items: center;
}

.document-top-settings {
  top: auto;

  @media all and (max-width: 768px) {
    top: -25px;
  }
}
