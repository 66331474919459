/* ------------------------------- tooltip ------------------------------- */

.tooltip {
  display: flex;
  gap: 5px;

  position: relative;

  .tooltip__btn {
    padding: 0;

    border: none;
    background-color: transparent;
    outline: none;
    cursor: pointer;

    img {
      max-width: 17px;
      width: 100%;
    }
  }

  .tooltip__text {
    position: absolute;
    top: -45px;
    left: 0;
    z-index: 1;

    display: none;
    width: auto;
    padding: 12px;

    font-family: "Plus Jakarta Sans", sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: normal;

    color: $blackRich;
    background-color: $greySoft;
    border-radius: 6px;

    transition: all 0.3s ease-in-out;

    @include respond-to("807px") {
      top: -62px;

      max-width: 650px;
    }

    @include respond-to("445px") {
      top: -80px;
    }
  }

  .tooltip__btn:hover + .tooltip__text,
  .tooltip__btn:focus + .tooltip__text {
    display: block;
  }
}
