@import "/src/styles/abstracts/mixins";

/* ------------------------------- card-mvp ------------------------------- */
.card-mvp {
  border-radius: 24px;
  background: rgba($white, 0.1);
  backdrop-filter: blur(40px);

  overflow: hidden;

  .card-mvp__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
    padding: 40px;

    overflow: hidden;
  }

  .card-mvp__wrapper--mvp-5 {
    padding-bottom: 10px;
  }

  .card-mvp__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }

  .card-mvp__content--left {
    align-items: flex-start;
  }

  .card-mvp__icon {
    height: 48px;
  }

  .card-mvp__text-wrapper {
  }

  .card-mvp__title {
    text-align: center;
  }

  .card-mvp__location {
    color: $white;
    line-height: 120%;
    margin: 0;
    display: flex;
    justify-content: center;
    text-align: center;

    input,
    textarea {
      font-family: "Averta CY", sans-serif;
      font-style: normal;
      font-weight: 700;
      line-height: 120%;
      font-size: 20px;

      color: $white;
      margin: 0;
      display: flex;
      justify-content: center;
      text-align: center;
      background-color: transparent;
      width: 200px;
      height: auto;
      min-height: 96px;
      outline: none;
      border-style: none;
      resize: none;
      overflow-y: hidden;
    }
  }

  .card-mvp__title--left {
    text-align: left;
  }

  .card-mvp__title--sell {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 10px;

    .active {
      text-decoration: underline;
      color: $pink;
    }

    a {
      color: $white;
      transition: all 0.2s ease-in-out;
    }

    a:hover {
      text-decoration: underline;
      color: $pink;
      cursor: pointer;
      transition: all 0.2s ease-in-out;
    }
  }

  .card-mvp__text {
    text-align: center;
  }

  .card-mvp__text--left {
    text-align: left;
  }

  .card-mvp__button-wrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
  }

  .card-mvp__button {
  }

  .card-mvp__gallery {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 7px;
    margin: 0 -30px;

    img {
      width: 156.5px;
      height: 160.5px;

      border-radius: 6px;
      object-fit: cover;
    }

    img:first-child {
      width: 320px;
      height: 292px;

      border-radius: 13px;
      object-fit: cover;
    }
  }

  .card-mvp__gallery--two {
    img {
      width: 320px;
      height: 292px;

      border-radius: 13px;
      object-fit: cover;
    }
  }
}

.card-mvp--mvp-5 {
  padding-bottom: 10px;
}

/* ------------------------------- card-expose ------------------------------- */
.card-expose {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  &__image {
    max-width: 170px;
  }

  &__title {
    max-width: 250px;

    @include respond-to("1300px") {
      max-width: none;
    }

    h5 {
      display: none;

      @include respond-to("1300px") {
        display: block;

        text-transform: uppercase;
      }
    }
  }

  &__address {
    max-width: 250px;

    @include respond-to("1300px") {
      max-width: none;
    }

    h5 {
      display: none;

      @include respond-to("1300px") {
        display: block;

        text-transform: uppercase;
      }
    }
  }

  &__price {
    max-width: 100px;

    @include respond-to("1300px") {
      max-width: none;
    }

    h5 {
      display: none;

      @include respond-to("1300px") {
        display: block;

        text-transform: uppercase;
      }
    }
  }

  &__area {
    max-width: 100px;

    @include respond-to("1300px") {
      max-width: none;
    }

    h5 {
      display: none;

      @include respond-to("1300px") {
        display: block;

        text-transform: uppercase;
      }
    }
  }

  &__available {
    max-width: 100px;

    @include respond-to("1300px") {
      max-width: none;
    }

    h5 {
      display: none;

      @include respond-to("1300px") {
        display: block;

        text-transform: uppercase;
      }
    }
  }

  &__status {
    max-width: 135px;
    width: 100%;

    @include respond-to("1300px") {
      max-width: none;
    }

    .wrapper {
      display: flex;
      flex-direction: column;
      gap: 5px;

      @include respond-to("1300px") {
        flex-direction: row;
        gap: 10px;
      }
    }

    h5 {
      display: none;

      @include respond-to("1300px") {
        display: block;
        margin-bottom: 3px;

        text-transform: uppercase;
      }
    }
  }

  &__id {
    max-width: 400px;

    h5 {
      display: none;

      @include respond-to("lg") {
        display: block;

        text-transform: uppercase;
      }
    }
  }

  &__date {
    max-width: 200px;

    h5 {
      display: none;

      @include respond-to("lg") {
        display: block;

        text-transform: uppercase;
      }
    }
  }

  &__amount {
    max-width: 200px;

    h5 {
      display: none;

      @include respond-to("lg") {
        display: block;

        text-transform: uppercase;
      }
    }
  }

  &__pending {
    max-width: 200px;

    @include respond-to("lg") {
      width: 100%;
    }

    @include respond-to("xs") {
      max-width: initial;
    }

    h5 {
      display: none;

      @include respond-to("lg") {
        display: block;
        width: 100%;
        margin-bottom: 3px;

        text-transform: uppercase;
      }
    }
  }

  &__invoice {
    max-width: 200px;

    @include respond-to("lg") {
      width: 100%;
    }

    @include respond-to("xs") {
      max-width: initial;
    }

    h5 {
      display: none;

      @include respond-to("lg") {
        display: block;
        margin-bottom: 3px;

        text-transform: uppercase;
      }
    }
  }

  .document-page__btns-sidebar {
    position: fixed;
    right: 40px;
    bottom: 74px;
    display: flex;
    justify-content: center;
    align-items: center;

    @include respond-to("md") {
      position: fixed;
      left: 0;
      bottom: 0;
      width: 100%;
      background-color: $white;
    }
  }

  .card-expose__control-btns {
    position: absolute;
    top: 40px;
    left: 50%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: row;
    gap: 16px;

    @include respond-to("lg") {
      left: 35%;
    }

    @include respond-to("sm") {
      gap: 10px;
      top: 20px;
    }
  }

  .card-expose__pages-btns {
    position: absolute;
    top: 40px;
    left: calc(100% - 313px - 96px);
    display: flex;
    flex-direction: row;
    gap: 16px;
    @media (max-width: 1228px) {
      left: 70%;
    }
  }

  .card-expose__control-select {
    width: 89px;
    height: 40px;
  }

  .card-expose__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-left: 300px;
    //padding: 40px;

    &.card-expose__content--big {
      margin-left: 0px;
    }

    @include respond-to("md") {
      margin-left: 0;
      padding-top: 0;
      padding-bottom: 0;
    }

    &.card-expose__content--with-sidebar {
      background-color: $whiteNeutrals;
      //margin-left: 353px;
      //margin-right: 353px;
      margin-top: 120px;
      margin-bottom: 74px;
      //max-width: 1214px;
      //max-width: calc(100% - 270px - 313px);

      @include respond-to("lg") {
        max-width: 567px;
      }

      @include respond-to("md") {
        margin-top: 0px;
        max-width: 100%;
        min-width: 320px;
        //background-color: $white;
      }
    }

    &.card-expose__content--no-data {
      background-color: $white;
      height: 80vh;
    }
  }

  .cards-container {
    overflow-y: auto;
    max-height: 55vh;
    width: 100%;
    &::-webkit-scrollbar-thumb {
      display: none;
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .cards-container__row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
  }
  .card-expose__realty {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    margin-bottom: 10px;
    cursor: pointer;
    width: 100%;
    background-color: rgba($greySoft, 0.2);
    @include respond-to("lg") {
      flex-wrap: wrap;
    }
  }

  .card-expose__realty > * {
    flex: 1 1 200px;

    @include respond-to("sm") {
      flex: 1 1 130px;
    }
  }

  .card-expose__realty--first {
    flex: 1 1 400px;

    @media all and (max-width: 1300px) {
      flex: 1 1 200px;
    }

    @include respond-to("sm") {
      flex: 1 1 130px;
    }
  }

  .card-expose__realty-image {
    border-radius: 4px;

    img {
      width: 170px;
      height: 95px;
      object-fit: cover;
      border-radius: 4px;
    }
  }
  .card-property__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 90px;
    width: 100%;
    margin-bottom: 40px;

    @include respond-to("md") {
      flex-direction: column;
    }
  }

  .card-property__header-titles,
  .card-property__header-titles-table {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    margin-bottom: 40px;
    margin-left: 100px;

    @include respond-to("md") {
      flex-wrap: wrap;
      margin-left: 0;
    }

    @include respond-to("sm") {
      margin-bottom: 0;
    }
  }

  .card-property__header-titles-table {
    margin-left: 0px;
    padding: 10px;
  }

  .card-property__header-title-first {
    @include respond-to('sm') {
      display: none !important;
    }
  }

  .card-property__header-titles-table > * {
    padding: 10px;
    display: flex;
    justify-content: flex-start;
    flex: 1 1 200px;

    @include respond-to("sm") {
      flex: 1 1 75px;
    }
  }

  .text-expose--first {
    flex: 1 1 400px;

    @media all and (max-width: 1300px) {
      flex: 1 1 200px;
    }

    @include respond-to("sm") {
      flex: 1 1 50px;
    }
  }

  .card-expose__icon--inner-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 40px;
    height: 446px;
    width: 500px;

    @include respond-to("sm") {
      width: 220px;
    }
  }
}

.card-settings {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .card-settings__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    //gap: 16px;
  }

  .card-settings__content--bottom-content {
    position: absolute;
    bottom: 40px;
  }

  .card-settings__header {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 40px;
    padding-left: 12px;

    width: 220px;
    cursor: pointer;

    @media all and (max-width: 1300px) {
      width: 161px;
    }

    @include respond-to("sm") {
      width: 150px;
    }

    &.card-settings__header-small {
      margin-bottom: 0;
    }
  }


  .settings-page__title {
    color: $blackRich;

    &.active {
      color: $blueTotal;
    }
  }

  .settings-page__title--gray {
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 26px;
    color: $silverSonic;

    &.active {
      color: $blackRich !important;
    }
  }

  .settings-page__menu {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    display: flex;
    padding: 11px 12px;
    gap: 10px;
    cursor: pointer;
    width: 220px;

    @media all and (max-width: 1300px) {
      width: 161px;
    }

    @include respond-to("sm") {
      width: 150px;
    }
  }
}

.section-realty-images__card {
  border-radius: 4px;
  border: 1px dashed #b7b8be;
  background: #fdfdfd;
  width: 273px;
  height: 154px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  cursor: move;
  font-size: 14px;
  z-index: 1;

  @media all and (max-width: 1200px) {
    width: 300px;
    height: 180px;
  }

  &.section-realty-images__card--first-card {
    @include respond-to("sm") {
      height: 263px;
    }
  }

  &.section-realty-images__card--accent-card {
    border: 1px dashed $accentDoc;
  }

  .section-realty-images__category {
    position: absolute;
    top: 8px;
    left: 8px;
    z-index: 100;
    //width: 124px;
    height: 34px;
    background-color: $background-white;
    color: $greyMetal;
    border-radius: 6px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px;
  }

  .section-realty-images__category--bottom {
    cursor: pointer;
    position: absolute;
    z-index: 100;
    //width: 124px;
    height: 34px;
    background-color: rgba($greenStrong, 0.6);
    color: $white;
    border-radius: 6px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px;
    bottom: 8px;
    right: 8px;
  }

  .section-realty-images__image {
    z-index: 1;

    input {
      z-index: 1;

      width: 273px;
      height: 154px;

      &.disabled {
        pointer-events: none;
      }
    }

    .avatar {
      display: flex;
      width: 273px;
      height: 154px;
      flex-shrink: 0;
      align-self: stretch;
      overflow: hidden;

      position: relative;
      //border: 2px dashed rgba(0, 0, 0, 0.1);
      //border-radius: 16px;

      input {
        cursor: pointer;
        outline: none;
        border-radius: 12px;
        display: block;
        width: 273px;
        height: 154px;
        opacity: 0;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        z-index: 1000;
      }

      .avatar-image {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }

      .avatar--placeholder {
        display: flex;
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        border-radius: 4px;
        //opacity: 0.4;


        img {
          width: 32px;
          height: 32px;
          opacity: 0.6;
          margin-bottom: 12px;
          border-radius: 4px;
        }

        .image-text {
          font-family: Averta;
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 16px;
          text-align: center;
          color: rgba(#000000, 0.8);

          &.cover {
            font-family: Averta;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 16px;
            color: rgba(#fff, 0.6);
          }
        }
      }
    }

    @media all and (max-width: 1200px) {
      width: 300px;
      height: 180px;

      input {
        width: 300px;
        height: 180px;
      }

      .avatar {
        width: 300px;
        height: 180px;

        input {
          width: 300px;
          height: 180px;
        }
      }
    }

    //@media all and (max-width: 768px) {
    //  width: 273px;
    //  height: 180px;
    //
    //  input {
    //    width: 273px;
    //    height: 180px;
    //  }
    //
    //  .avatar {
    //    width: 273px;
    //    height: 180px;
    //
    //    input {
    //      width: 273px;
    //      height: 180px;
    //    }
    //  }
    //}

    &.section-realty-images__image--dim {
      opacity: 0.5;
    }

    &.section-realty-images__image--first-card {
      width: 320px;
      height: 263px;

      input {
        width: 320px;
        height: 263px;
      }

      .avatar {
        width: 320px;
        height: 263px;

        input {
          width: 320px;
          height: 263px;
        }
      }
    }

    .section-realty-images__btn {
      margin-top: 14px;

      display: flex;
      width: 210px;
      height: 36px;
      padding: 12px 8px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;

      border-radius: 4px;
      background: $greySoft;
      outline: none;
      border: none;
    }

    .section-realty-images__image--title {
      width: 210px;
      text-align: center;
      margin-top: 8px;
    }
  }
}

/* ----------------------------- card-doc ----------------------------- */
.card-doc {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  padding: 40px;

  border: 1px solid $greySoft;
  border-radius: 4px;

  .card-doc__icon {
  }

  .card-doc__content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 8px;
    height: 100%;
  }

  .card-doc__title {
  }

  .card-doc__text {
  }

  .card-doc__btn {
  }
}

.card-doc:hover {
  border: 1px solid $blueTotal;

  transition: all 0.2s ease-in-out;
}
/* -------------------------------- Base card ------------------------------- */

.base-card {
  position: relative;

  max-width: 355px;
  width: 100%;
  padding: 40px;

  border: 1px solid $greySoft;
  border-radius: 4px;
  cursor: pointer;

  transition: border-color 0.08s ease-in-out;

  &:hover {
    border-color: $blueTotal;
    .button {
      background-color: $greySoft;
    }
  }

  &.base-card--packages {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 0;
    flex: 1 1 24%;
    max-width: 279px !important;

    @include respond-to("md") {
      flex: 1 1 100%;
      max-width: 100% !important;

    }

    &:hover {
      border-color: $silverSonic;
      .button {
        background-color: $blueTotal;
      }
    }

    .button {
      transition: 0.5s;
      &:hover {
        background-color: $blueDeep !important;
      }
    }

    .base-card__subscriptions-info {
      display: flex;
      flex-direction: column;
      padding: 27px;
      gap: 10px;
    }
  }

  &.base-card__active {
    background-color: $greySoft;
  }

  .base-card__icon {
    margin-bottom: 24px;

    img {
      width: 48px;
      height: 48px;
    }
  }
  .base-card__title {
    font-size: 16px;
    font-weight: 600;
    line-height: 26px;
    margin-bottom: 8px;
  }

  .base-card__description {
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    color: $silverSonic;
    margin-bottom: 24px;
  }

  .base-card__controls {
    .button {
      width: 100%;
    }
  }

  .base-card__badge-container {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
  }

  .base-card__badge {
    width: 50%;
    height: 38.8px;
    padding: 5px 5px 5px 5px;
    background-color: $greySoft;
    border-style: none;
    border-radius: 5px 5px 5px 5px;
    font-size: 16px;
    color: rgb(117, 119, 128);
    display: flex;
    justify-content: center;
    align-items: center;

    &.base-card__badge--green {
      background-color: $greenLight;
      color: $greenStrong;
    }
    &.base-card__badge--orange {
      background-color: $orangeSoft;
      color: $orangeStrong;
    }
    &.base-card__badge--red {
      background-color: $redSoft;
      color: $redStrong;
    }
  }
  .base-card__prices {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    font-family: "Plus Jakarta Sans", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    color: $silverSonic;

    .base-card__price-crossed {
      text-decoration-line: line-through;
    }

    .base-card__price-active {
      color: $blueTotal;
    }
  }

  .base-card__link {
    height: 43.2px;
    a {
      text-decoration-line: underline;
      color: $blueTotal;
      font-size: 16px;
      font-family: "Plus Jakarta Sans", sans-serif;
      font-weight: 600px;
      cursor: pointer;
    }
  }

  .base-card__list-item {
    display: flex;
    flex-direction: row;
    gap: 5px;
    margin-bottom: 5px;
  }

  &__content {
  }

  &--setting {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

/* ------------------------------- card-payment ------------------------------- */

.payment-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  //margin-top: 20px;

  .card-payment__header {
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: flex-start;
    justify-content: center;
    padding-left: 20px;
  }
}

.card-payment {
  max-width: 480px;
  border-radius: 4px;
  //margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  //gap: 10px;
  overflow-x: hidden;
  overflow-y: hidden;

  @include respond-to(desktop) {
    margin-right: auto;
    margin-left: auto;
  }

  @media screen and (max-width: 440px) {
    padding: 0 20px;
  }

  &.disabled {
    pointer-events: none;
    opacity: 0.7;
  }
}

.card-payment__divider {
  height: 1px;
  width: 100%;
  max-width: 480px;
  background-color: #d9d9d9;
}

.card-payment__notification {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.card-payment__discount {
  line-height: 13px;
  margin-top: 10px;
}

.header-card {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  @media screen and (max-width: 440px) {
    flex-direction: column;
  }
}


.cards-container__row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 10px;
}

.staging-menu__card {
  width: 165px;
  padding-bottom: 10px;
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  height: fit-content;
  text-align: start;

  .staging-menu__container-top {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    .staging-menu__button-top {
      width: 100%;
      height: 30px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: transparent;
      color: $silverSonic;
      border: transparent;

      font-family: "Plus Jakarta Sans", sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      margin-bottom: 5px;

      &:hover  {
        color: $blackSemi;
      }

      &.active {
        background-color: $silverSonic;
        color: $black;
        border-radius: 8px;

        .staging-menu__button-badge {
          background-color: $greyMetal;
        }
      }
    }

    .staging-menu__button-inner {
      display: flex;
      align-items: center;
      gap: 5px;
    }

    .staging-menu__btn-inner-text {
      line-height: 15px;
      text-align: start;
    }

    .staging-menu__button-badge {
      text-align: center;
      padding: 0px 4px;
      border-radius: 1000px;
      background-color: $greyMetal;
      min-width: 32px;
    }

    .staging-menu__divider {
      width: 100%;
      height: 1px;
      background-color: $silverSonic;
    }

    .staging-menu__button-middle {
      width: 100%;
      height: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: transparent;
      color: $silverSonic;
      border-radius: 8px;
      border: 1px solid $silverSonic;

      font-family: "Plus Jakarta Sans", sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;

      &:hover {
        color: $blackSemi;
      }
    }

    .staging-menu__input {
      width: 100%;
      background-color: $white;
      display: flex;
      border: 1px solid $silverSonic;
      border-radius: 8px;
      padding: 4px 2px;
      height: 32px;

      &:active {
        outline: 1px solid $blueTotal;
      }

      input {
        width: 100%;
        background: none;
        border: none;
        outline: none;
        padding-left: 5px;
      }
      .staging-menu__input-span {
        line-height: 20px;
        display: flex;
        align-items: center;
        gap: 10px;
      }
    }

    .staging-menu__input-svg {
      cursor: pointer;
      transition-delay: 0.5s;
      rotate: 45deg;

      &:hover {
        color: $blueTotal;
      }

      &.staging-menu__input-svg--rotated {
        rotate: 0deg;
      }
    }

    .staging-menu__devider-vertical {
      height: 80%;
      width: 1px;
      background-color: $silverSonic;
    }
  }


  .staging-menu__container-bottom {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    max-height: 500px;
    margin-top: 20px;
    //max-height: 380px;
    padding: 2px;
    overflow-y: auto;

    &::-webkit-scrollbar-thumb {
      background-color: $greyMetal;
      border: 1px solid rgba($white, 0.1);
    }

    &::-webkit-scrollbar-track {
      border-radius: 4px;
      background-color: $greySoft;
    }

    .staging-menu__folder-btn {
      height: 30px;
      padding: 4px;
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-radius: 8px;
      background-color: rgba($greyMetal, 0.6);
      color: $silverSonic;
      border: transparent;

      font-family: "Plus Jakarta Sans", sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;

      .staging-menu__folder-btn-badge {
        display: block;
        padding: 0px 4px;
        border-radius: 1000px;
        background-color: $greyMetal;
        min-width: 32px;
        text-align: center;
      }

    &.active {
      background-color: $silverSonic;
      color: $blackSemi;

      .staging-menu__folder-btn-text {
        color: $blackSemi !important;
      }

      .staging-menu__folder-btn-badge {
        background-color: $greyMetal;
      }
    }

      &:hover {
        color: $blackSemi;

        .staging-menu__folder-btn-text {
          color: $blackSemi !important;
        }

        .staging-menu__folder-btn-delete {
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .staging-menu__folder-btn-badge {
          display: none;
        }
      }
    }
    .staging-menu__folder-btn-inner {
      display: flex;
      align-items: center;
    }
    .staging-menu__folder-btn-text {
      width: 90px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: $silverSonic;
      text-align: center;
    }

    .staging-menu__folder-btn-delete {
      background: transparent;
      border: transparent;
      display: none;
      color: $red;
      //margin-left: 16px;
    }
  }
}
