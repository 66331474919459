// -----------------------------------------------------------------------------
// This file contains CSS helper classes.
// -----------------------------------------------------------------------------

.container-mvp {
  max-width: 360px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 0 10px;
}

.container-settings {
  width: 300px;
  padding: 40px;

  @include respond-to("sm") {
    width: 160px;
    padding: 10px;
  }

  &.container-settings--document-settings {
    max-width: 313px;
    //width: calc(18.5vw - 40px);
    height: 100%;
    min-height: 80vh;
    overflow: hidden;
  }
}

.container-expose {
  width: 100%;
  height: 100%;
}

.container-expose-step {
  max-width: 940px;
  width: 100%;
  margin: 0 auto;
  padding: 0 20px;
}

.container-expose-step__summary {
  transform: scale(0.9);
  margin-top: -25px;
  //margin-top: -42px;
  margin-left: 0px;

  @include respond-to('md') {
    transform: scale(0.9);
    //margin-top: -42px;
    margin-left: 0;
  }
  @include respond-to('sm') {
    transform: scale(0.6);
    margin-top: -100px;
    margin-left: 0;
  }
}


.container-onboarding {
  max-width: 740px;
  width: 100%;
  margin: 0 auto;
  padding: 0 20px;
}
