.dashboard-widgets-section {
  .container {
  }
}

.dashboard-widget {
  padding: 40px;
  min-height: 384px;

  .dashboard-widget__title {
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
    margin-bottom: 8px;
    color: $black;
  }
  .dashboard-widget__subtitle {
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    color: $greyMetal;
  }

  &.dashboard-widget--info {
    max-width: 700px;
    display: flex;
    flex-direction: column;
    align-items: center;
    //gap: 40px;

    .dashboard-widget__subtitle {
      text-align: center;
      margin-bottom: 40px;
    }
  }
}



.dashboard-widget--default {
  background-image: url("/assets/images/real-estate-search.svg");
  background-repeat: no-repeat;
  background-size: auto 90%;
  background-position: bottom right;
  max-width: 916px;

  .dashboard-widget__title,
  .dashboard-widget__subtitle {
    max-width: 310px;
  }
  .dashboard-widget__subtitle {
    margin-bottom: 28px;
  }
}
.dashboard-widget--immobilen {
  width: 367px;
  background-image: url("/assets/images/immobilen.svg");
  background-repeat: no-repeat;
  background-size: auto 90%;
  background-position: bottom right;
  max-width: 916px;

  .dashboard-widget__title,
  .dashboard-widget__subtitle {
    max-width: 310px;
  }
  .dashboard-widget__subtitle {
    margin-bottom: 28px;
  }
}

.dashboard-widget--total {
  background-image: url("/assets/images/real-estate.svg");
  background-repeat: no-repeat;
  background-size: auto 90%;
  background-position: bottom right;
  max-width: 916px;

  .total__count {
    font-size: 72px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
}
