.logo-list {
  display: grid;
  grid-template: auto / repeat(auto-fill, minmax(280px, 1fr));
  grid-gap: 40px;
  @include respond-to("md") {
    grid-gap: 10px;
  }
  .logo__card {
    border-radius: 4px;
    flex-shrink: 0;
    background-color: $whiteNeutrals;
    overflow: hidden;
    width: 100%;
    height: 205px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 40px;
    position: relative;
    border: 1px solid transparent;

    img {
      width: 100%;
      height: auto;
      object-fit: cover;
    }

    .app-button {
      display: none;
      position: absolute;
      top: 16px;
      right: 16px;
      z-index: 30;
    }

    &:hover {
      border-color: $blueTotal;
      .app-button {
        display: flex;
      }
    }
  }
}

.color-list {
  display: grid;
  grid-template: auto / repeat(auto-fill, minmax(430px, 2fr));
  grid-gap: 40px;
  max-width: 100%;

  @include respond-to("md") {
    grid-gap: 10px;
  }

  @include respond-to("sm") {
    grid-template: auto / repeat(auto-fill, minmax(270px, 2fr));
  }

  .color-card {
    padding: 24px;
    border: 1px solid $greySoft;
    border-radius: 4px;
    position: relative;

    display: flex;
    align-items: center;
    width: 100%;

    .app-button {
      display: none;
      position: absolute;
      top: 16px;
      right: 16px;
      z-index: 30;
    }

    &:hover {
      border-color: $blueTotal;
      .app-button {
        display: flex;
      }
    }
  }

  .color-card__color-preview {
    width: 92px;
    height: 92px;
    border-radius: 4px;
    background: $greySoft;
    margin-right: 24px;
    flex-shrink: 0;
  }

  .color-card__controls {
    .color-card__name {
      margin-bottom: 16px;

      font-size: 20px;
      font-weight: 600;
      line-height: 30px;
      text-overflow: ellipsis;

      input {
        width: 100%;
        border: none;
        appearance: none;
        outline: none;
        font-family: "Plus Jakarta Sans", sans-serif;
        font-size: 20px;

        font-weight: 600;
        line-height: 30px;

        text-overflow: ellipsis;
      }
    }
    .row {
      display: flex;
      align-items: center;

      .color-card__value {
        position: relative;

        .color-card__value-prefix {
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 40px;
          position: absolute;
          left: 12px;
          top: 0;
          color: $greyMetal;
          user-select: none;
        }

        input {
          width: 100%;
          border: 1px solid $greySoft;
          appearance: none;
          outline: none;
          font-family: "Plus Jakarta Sans", sans-serif;
          font-size: 14px;

          font-weight: 600;
          line-height: 36px;

          text-overflow: ellipsis;
          width: 100px;

          padding-left: 26px;
        }
      }
    }
  }
}

/* ------------------------------- font-list ------------------------------- */

.font-list {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

/* ------------------------------- font ------------------------------- */

.font {
  display: flex;
  align-items: center;
  gap: 25px;
  min-height: 68px;
  padding: 14px 16px;

  border: 1px solid $greyMetal;
  border-radius: 4px;
  background-color: $whiteSnow;

  transition: all 0.2s ease-in-out;

  &:hover {
    background-color: $whiteNeutrals;

    .font__edit {
      display: block;

      cursor: pointer;

      &:hover {
        mat-icon {
          svg path {
            fill: $blueTotal;
          }
        }
      }
    }
  }

  &.active {
    background-color: $whiteNeutrals;

    .font__edit {
      display: block;

      cursor: pointer;

      &:hover {
        mat-icon {
          svg path {
            fill: $blueTotal;
          }
        }
      }
    }
  }

  .font__wrapper {
    display: flex;
    align-items: baseline;
    gap: 25px;
    width: 100%;

    @include respond-to("430px") {
      flex-direction: column;
    }
  }

  .font__title {
    line-height: normal;
  }

  .font__settings {
    display: flex;
    align-items: flex-end;
    gap: 8px;
  }

  .font__size {
    font-size: 13px;
    font-weight: 500;
    line-height: 18px;

    color: $silverSonic;
  }

  .font__weight {
    font-size: 13px;
    font-weight: 500;
    line-height: 18px;

    color: $silverSonic;
  }

  .font__edit {
    display: none;
  }
}
