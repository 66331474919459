/* ------------------------------- form-mvp ------------------------------- */
.form-mvp {
  display: flex;
  height: 49px;
  padding: 15px 30px;

  border-radius: 1000px;
  background: rgba($white, 0.1);

  transition: all 0.3s ease-in-out;

  input {
    width: 100%;

    background: none;
    border: none;
    outline: none;
  }

  input[type="text"] {
    font-family: "Averta CY", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    text-align: center;

    color: $white;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:focus {
    transition:
      background-color 600000s 0s,
      color 600000s 0s;
  }

  input[type="text"]::placeholder {
    font-family: "Averta CY", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;

    color: rgba($white, 0.4);
  }

  &.active {
    background: rgba($white, 0.2);
  }
}

.form-mvp:hover,
.form-mvp:active,
.form-mvp:focus-within {
  background: rgba($white, 0.2);

  transition: all 0.3s ease-in-out;
}

/* ------------------------------- form-group ------------------------------- */
.form-group-expose {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;

  .form-group-expose__error {
    font-family: "Plus Jakarta Sans", sans-serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;

    color: $redStrong;
  }

  &__hidden-label {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    overflow: hidden;
    white-space: nowrap;
    border: 0;
  }
}

.form-group-onboarding {
  align-items: flex-start;
}

/* ------------------------------- form-expose ------------------------------- */
.form-expose--error {
  border: 1px solid $redStrong !important;
}

.form-expose {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  height: 50px;
  padding: 13px 16px;

  border-radius: 4px;
  border: 1px solid $greyMetal;
  background: transparent;

  transition: all 0.2s ease-in-out;

  .form-expose__text {
    font-family: "Plus Jakarta Sans", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    color: $blackRich;
  }

  &.disabled {
    background-color: $whiteNeutrals;

    pointer-events: none;

    input[type="text"] {
      font-family: "Plus Jakarta Sans", sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;

      color: $silverSonic;
    }
  }

  &.disabled--checkbox {
    background-color: $whiteNeutrals;

    input {
      pointer-events: none;
    }

    input[type="text"] {
      pointer-events: none;
    }
  }

  &.form-expose--disabled {
    input {
      pointer-events: none;
    }

    input[type="text"] {
      pointer-events: none;
    }
  }

  &.error {
    border: 1px solid $red;
  }

  input {
    width: 100%;

    background: none;
    border: none;
    outline: none;
  }

  input[type="text"],
  input[type="email"],
  input[type="password"] {
    padding: 0;

    font-family: "Plus Jakarta Sans", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    color: $blackRich;
  }

  input[type="text"]::placeholder,
  input[type="email"]::placeholder,
  input[type="password"]::placeholder {
    font-family: "Plus Jakarta Sans", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    color: $silverSonic;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:focus {
    transition:
      background-color 600000s 0s,
      color 600000s 0s;
  }

  .form-expose__btn {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 8px;

    .form-expose__remove {
      cursor: pointer;

      &:hover {
        background-color: $greySoft;
        border-radius: 4px;

        transition: all 0.2s ease-in-out;
      }
    }

    .form-expose__count {
    }

    .form-expose__add {
      cursor: pointer;

      &:hover {
        background-color: $greySoft;
        border-radius: 4px;

        transition: all 0.2s ease-in-out;
      }
    }
  }

  .form-expose__checkbox {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    flex-shrink: 0;

    border: 2px solid $silverSonic;
    border-radius: 50%;

    cursor: pointer;
    transition: all 0.2s ease-in-out;
  }

  .form-expose__icon {
    display: block;
    opacity: 0;
    user-select: none;

    transition: all 0.2s ease-in-out;
  }

  .form-expose__active {
    border: 2px solid $blueTotal;

    transition: all 0.2s ease-in-out;

    img {
      opacity: 1;
    }
  }

  .form-expose__mat-icon {
    display: flex;
    max-width: 30px;
    width: 100%;
    max-height: 14px;
    height: 100%;
  }

  .form-expose__remove {
    cursor: pointer;

    &:hover {
      background-color: $greySoft;
      border-radius: 4px;

      transition: all 0.2s ease-in-out;
    }
  }

  .form-expose__count {
    text-align: center;
  }

  .form-expose__add {
    cursor: pointer;

    &:hover {
      background-color: $greySoft;
      border-radius: 4px;

      transition: all 0.2s ease-in-out;
    }
  }

  &:hover {
    border: 1px solid $blueTotal;
  }

  &:focus,
  &:focus-within {
    border: 1px solid $blueTotal;

    transition: all 0.2s ease-in-out;
  }
}

.form-expose--size {
  width: 85px;
  height: 32px;

  padding: 5px 5px 5px 10px;
}

.form-expose--weight {
  width: 115px;
  height: 32px;

  padding: 5px 5px 5px 10px;
}

.form-expose--silverSonic {
  input[type="text"] {
    color: $silverSonic;
  }
}

.form-expose--search {
  position: relative;

  .form-expose__close {
    display: flex;

    cursor: pointer;
  }

  ul {
    position: absolute;
    top: 49px;
    right: -1px;
    left: -1px;
    z-index: 2;

    max-height: 250px;

    background-color: $whiteSnow;
    border-top: 1px solid $greySoft;
    border-right: 1px solid $blueTotal;
    border-bottom: 1px solid $blueTotal;
    border-left: 1px solid $blueTotal;
    border-radius: 0 0 4px 4px;

    transition: all 0.2s ease-in-out;

    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $greySoft;
      border-radius: 1000px;
      border: 1px solid rgba($white, 0.1);
    }

    &::-webkit-scrollbar-track {
      border-radius: 1000px;
      background-color: rgba($white, 0.1);
    }
  }

  li {
    padding: 13px 16px;

    border-bottom: 1px solid $greySoft;

    transition: all 0.2s ease-in-out;
    cursor: pointer;
  }

  li:hover {
    background-color: $background-white;

    transition: all 0.2s ease-in-out;
  }

  li:last-child:hover {
    border-radius: 0 0 4px 4px;
  }

  &.focus {
    border-radius: 4px 4px 0 0 !important;
    border-top: 1px solid $blueTotal;
    border-right: 1px solid $blueTotal;
    border-left: 1px solid $blueTotal;
    border-bottom: 1px solid $greySoft;

    transition: all 0.2s ease-in-out;
  }
}

.form-expose__zoom {
  height: 40px;
  background: $whiteNeutrals;
}
.form-expose--tag {
  min-height: 50px;
  height: auto;
  padding: 5px 8px;
}

.form-expose--tag-small {
  min-height: 50px;
  height: auto;
  padding: 8px;
}

/* ------------------------------- input-search ------------------------------- */
.input-search {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  height: 54px;
  padding: 14px 16px;

  background: transparent;
  border-radius: 4px;
  border: 1px solid $greySoft;

  transition: all 0.2s ease-in-out;

  @include respond-to("sm") {
    background-color: $whiteNeutrals;
    border-top: 1px solid $greySoft;
    border-right: inherit;
    border-bottom: 1px solid $greySoft;
    border-left: inherit;
  }

  input {
    width: 100%;
    background: none;
    border: none;
    outline: none;
  }

  input[type="text"] {
    padding: 0;

    font-family: "Plus Jakarta Sans", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px;

    color: $blackRich;
  }

  input[type="text"]::placeholder {
    font-family: "Plus Jakarta Sans", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px;

    color: $greyMetal;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:focus {
    transition:
      background-color 600000s 0s,
      color 600000s 0s;
  }

  label {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    overflow: hidden;
    white-space: nowrap;
    border: 0;
  }
}

.input-search:hover,
.input-search:focus-within {
  border: 1px solid $blueTotal;

  transition: all 0.2s ease-in-out;
}

/* ------------------------------- others style ------------------------------- */

.sr-only {
  position: absolute;

  width: 1px;
  height: 1px;
  padding: 0;

  white-space: nowrap;

  border: 0;

  overflow: hidden;
}

/* ------------------------------- File input ------------------------------- */

.file-input {
  cursor: pointer;

  &.file-input--wide {
    .file-input__placeholder {
      padding: 80px;
      min-height: 268px;
    }
    .file-input__progress-placeholder {
      padding: 80px;
      min-height: 268px;
    }
  }
  .file-input__placeholder {
    border-radius: 4px;
    border: 1px dashed $greyMetal;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    position: relative;
    transition: background-color 0.08s ease-in-out;
    padding: 20px;
    height: 100%;

    &:hover {
      background-color: $whiteNeutrals;
    }
  }

  .file-input__progress-placeholder {
    border-radius: 4px;
    border: 1px dashed $greyMetal;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    height: 100%;
    position: relative;

    overflow: hidden;
    padding: 20px;

    .file-input__progress-info {
      p {
        font-size: 14px;
        line-height: 24px;
        color: $silverSonic;
      }

      h3 {
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
      }
    }

    .file-input__progress-bar {
      margin-top: 16px;
      margin-bottom: 8px;
      position: relative;
      width: 100%;
      height: 8px;
      border-radius: 10px;
      background-color: $greySoft;
      overflow: hidden;

      span {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        border-radius: 10px;
        background-color: $blueTotal;
        width: 0;
        transition: width 1s ease-out;
      }
    }

    .file-input__progress-percents {
      font-size: 13px;
      font-weight: 500;
      line-height: 18px;
      color: $greyMetal;
    }
  }

  .file-input__icon {
    mat-icon {
      width: 32px;
      height: 32px;

      path {
        stroke: $silverSonic;
        fill: $silverSonic;
      }
    }

    &.file-input__icon--accent {
      margin-bottom: 14px;

      mat-icon {
        width: 24px;
        height: 24px;

        path {
          stroke: $blueTotal;
        }
      }
    }
  }

  .file-input__title {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    h3 {
      font-size: 14px;
      font-weight: 600;
      line-height: 24px;
      @include respond-to("md") {
        display: none;
      }
    }
    .button {
      padding: 10px 8px;
      line-height: 16px;
      margin: 0 6px;
    }
  }

  .file-input__description {
    font-size: 13px;
    line-height: 18px;
    text-align: center;

    color: $silverSonic;
  }

  .file-input__hidden-input {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    user-select: none;
    cursor: pointer;
  }
}

/* ------------------------------- select ------------------------------- */

.select {
  position: relative;

  cursor: pointer;

  .select__input {
    width: 100%;
    height: 50px;
    padding: 16px 0;

    input {
      cursor: pointer;
      pointer-events: none;
    }
  }

  .select__options {
    display: none;

    &.open {
      display: block;
    }
  }

  .select__arrow {
    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 24px;
    width: 100%;

    cursor: pointer;
    user-select: none;
  }

  .select__arrow .select__down {
    position: absolute;
    animation: keyframes-return 0.3s;
    transform: rotate(0deg);
  }

  //.select__arrow input:checked ~ .select__down {
  //  animation: keyframes-rotate 0.5s;
  //  transform: rotate(0deg);
  //}

  .select__animation {
    animation: keyframes-rotate 0.3s;
    transform: rotate(180deg);
  }

  .select__arrow input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  @keyframes keyframes-rotate {
    0% {
      transform: rotate(0deg);
      opacity: 0;
    }

    100% {
      transform: rotate(180deg);
    }
  }

  @keyframes keyframes-return {
    0% {
      transform: rotate(180deg);
      opacity: 0;
    }

    100% {
      transform: rotate(0deg);
    }
  }

  ul {
    position: absolute;
    top: 49px;
    right: -0.9px;
    left: -0.6px;
    z-index: 2;

    height: auto;

    background-color: $whiteSnow;
    border-top: 1px solid $greySoft;
    border-right: 1px solid $blueTotal;
    border-bottom: 1px solid $blueTotal;
    border-left: 1px solid $blueTotal;
    border-radius: 0 0 4px 4px;

    transition: all 0.2s ease-in-out;
  }

  li {
    padding: 13px 16px;

    transition: all 0.2s ease-in-out;
    cursor: pointer;
  }

  li:hover {
    background-color: $background-white;

    transition: all 0.2s ease-in-out;
  }

  li:last-child:hover {
    border-radius: 0 0 4px 4px;
  }

  &.focus {
    border-radius: 4px 4px 0 0 !important;
    border-bottom: 1px solid $greySoft;

    &:focus,
    &:focus-within {
      border-top: 1px solid $blueTotal;
      border-right: 1px solid $blueTotal;
      border-left: 1px solid $blueTotal;
      border-bottom: 1px solid $greySoft;

      transition: all 0.2s ease-in-out;
    }
  }
}

.select--small {
  //bottom: 5px;

  ul {
    top: 31px;
  }

  li {
    padding: 5px 5px 5px 10px;
  }
}

.form-payment {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  height: 50px;
  padding: 13px 16px;

  border-radius: 4px;
  border: none;
  background: $greySoft;

  transition: all 0.2s ease-in-out;

  &.disabled {
    background-color: $whiteNeutrals;

    pointer-events: none;
  }

  &.form-expose--disabled {
    input {
      pointer-events: none !important;
    }

    input[type="text"] {
      pointer-events: none !important;
    }

    input[type="text"]::placeholder {
      color: $blackRich !important;
    }
  }

  &.error {
    border: 1px solid $red;
  }

  input {
    width: 100%;

    background: $greySoft;
    border: none;
    outline: none;
  }

  input[type="text"],
  input[type="email"],
  input[type="password"] {
    padding: 0;

    font-family: "Plus Jakarta Sans", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    color: $blackRich;
  }

  input[type="text"]::placeholder,
  input[type="email"]::placeholder,
  input[type="password"]::placeholder {
    font-family: "Plus Jakarta Sans", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    color: $silverSonic;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:focus {
    transition:
      background-color 600000s 0s,
      color 600000s 0s;
  }
}

.form-group-payments {
  display: flex;
  flex-direction: row;
  gap: 8px;
  width: 100%;
}

/* ------------------------------ profile-form ----------------------------- */

.profile-form {
  display: flex;
  flex-direction: column;
  gap: 32px;
  width: 100%;

  &__row {
    display: flex;
    flex-direction: row;
    gap: 32px;

    @include respond-to("md") {
      flex-direction: column;
    }
  }

  &__col {
  }

  &__field {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  &__label {
  }

  &__textarea {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    max-width: 800px;
    width: 100%;
    height: 175px;
    padding: 13px 16px;

    font-family: "Plus Jakarta Sans", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;

    color: $blackRich;
    border-radius: 4px;
    border: 1px solid $greyMetal;
    background: transparent;
    outline: none;

    transition: all 0.2s ease-in-out;

    &:hover,
    &:focus {
      border: 1px solid $blueTotal;

      transition: all 0.2s ease-in-out;
    }
  }

  a {
    color: $blueTotal;
    cursor: pointer;

    transition: all 0.2s ease-in-out;

    &:hover {
      color: $blueDeep;
    }
  }
}
