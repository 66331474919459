/* - App footer - */

.footer {
  width: 100%;
  background-color: $blackRich;
  padding: clamp(4rem, 3.76915rem + 1.026vw, 5rem) 0;
  padding-left: 20px;

  .footer-container {
    display: flex;
    flex-direction: row;
    width: initial;
    height: 100%;
    flex-grow: 1;
    align-self: stretch;
    gap: 20px 20px;
    flex-wrap: wrap;
    justify-content: center;
    flex-basis: auto;
    flex-shrink: 1;

    background-transition: 0.3s;

    &.footer-container__rights {
      font-family: "Plus Jakarta Sans", sans-serif;
      margin-top: 20px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 13px;
      font-weight: 500;
    }

    @include respond-to('md') {
      flex-direction: column;
    }
  }
  .footer-container__info-col {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 20%;

    &.footer-container__info-col--first {
      width: 35%
    }

    @include respond-to('md') {
      width: 100% !important;
    }

    .footer-container__title {
      font-family: "Plus Jakarta Sans", sans-serif;
      font-size: 24px;
      font-weight: 600;
      font-style: normal;
      color: rgb(183, 184, 190);
    }
    .footer-container__description {
      color: rgb(253, 253, 253);
      font-family: "Plus Jakarta Sans", sans-serif;
      font-size: 16px;
      font-weight: 500;
      font-style: normal;
      line-height: 28.8px;

      &.footer-container__description--clickable {
        cursor: pointer;

       &:hover {
         color: rgb(183, 184, 190);
       }
      }


    }
  }
}
