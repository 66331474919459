@charset "UTF-8";

@import "primeng/resources/themes/lara-light-blue/theme.css";
@import "primeng/resources/primeng.css";

// Configuration and helpers
@import "abstracts/variables", "abstracts/functions", "abstracts/mixins";

// Vendors
@import "vendors/reset";

// Base stuff
@import "base/base", "base/fonts", "base/typography", "base/helpers",
  "base/angular-material";

// Components
@import "components/buttons", "components/inputs", "components/cards",
  "components/modals", "components/notifications", "components/tooltips";

// Layout-related sections
@import "layout/content", "layout/navigation", "layout/sections",
  "layout/common", "layout/header", "layout/footer";

// Page-specific styles
@import "pages/landing", "pages/dashboard", "pages/settings";

// Themes
@import "themes/default";
