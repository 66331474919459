/* ------------------------------- info-snack-bar ------------------------------- */
.info-snack-bar {
  .snack-bar {
    p {
      font-family: "Plus Jakarta Sans", sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 26px;
    }
  }
}

/* ------------------------------- error-snack-bar ------------------------------- */
.error-snack-bar {
  .mdc-snackbar__surface {
    background-color: $redSoft !important;
  }

  .snack-bar {
    display: flex;
    flex-direction: row;
    gap: 15px;

    p, a {
      font-family: "Plus Jakarta Sans", sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 26px;

      color: $redStrong;
    }

    a {
      text-decoration: underline;
      margin-left: auto;
    }
  }
}

/* ------------------------------- success-snack-bar ------------------------------- */
.success-snack-bar {
  .mdc-snackbar__surface {
    background-color: $greenLight !important;
  }

  .snack-bar {
    display: flex;
    flex-direction: row;
    gap: 15px;

    p, a {
      font-family: "Plus Jakarta Sans", sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 26px;

      color: $greenStrong;
    }

    a {
      text-decoration: underline;
      margin-left: auto;
    }
  }
}
/* ------------------------------- confirmation dialog ------------------------------- */
.confirm-dialog {
  //padding: 20px;
  max-height: 300px;
  max-width: 500px;
}
.confirm-dialog__container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: flex-start;
  align-items: flex-start;

  .confirm-dialog__header {
    font-family: "Plus Jakarta Sans", sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px;
    color: $blackRich;
  }
  .confirm-dialog__text {
    font-family: "Plus Jakarta Sans", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 30px;
    color: $blackRich;
  }

  .confirm-dialog__btns {
    margin-left: auto;
    display: flex;
    gap: 10px;
  }

  .confirm-dialog__btn-cancel, .confirm-dialog__btn-ok {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 10px 18px;

    font-family: "Plus Jakarta Sans", sans-serif;
    font-size: 14px;

    font-weight: 600;
    line-height: 24px;

    cursor: pointer;
    outline: none;
    border-radius: 20px;

    transition: background-color 0.08s ease-in-out;
    border: 1px solid $greenStrong;
    color: $greenStrong;
    background-color: rgba($greenLight, 1);
    width: 115px;

    &:hover {
      background-color: #c5fcc2;
    }
  }

  .confirm-dialog__btn-ok {
    background-color: $greenStrong;
    color: $white;

    &:hover {
      background-color: rgba($greenStrong, 0.7);
    }
  }
}
