@import "/src/styles/abstracts/variables";

// -----------------------------------------------------------------------------
// This file contains all styles related to the button component.
// -----------------------------------------------------------------------------

/* ------------------------------- button-mvp ------------------------------- */
.button-mvp {
  position: relative;

  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 49px;
  padding: 15px 25px;

  font-family: "Averta CY", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;

  color: $white;
  border-radius: 1000px;
  border: none;
  background: linear-gradient(265deg, $pink 2.96%, $violet 53.79%),
    linear-gradient(86deg, #ffedad 46.43%, #ffffe5 100%);
  outline: none;

  transition: all 0.3s ease-in-out;

  cursor: pointer;
  overflow: hidden;
}

.button-mvp--pink {
  background: rgba($pink, 0.2);
}

.button-mvp:hover,
.button-mvp:focus-within {
  transform: scale(1.03);
  border-color: #fff9;
}

.button-mvp--pink:hover {
  background: rgba($pink, 0.3) !important;
}

.button-mvp:hover {
  background: linear-gradient(
      265deg,
      rgba(255, 129, 255, 0.9) 2.96%,
      rgba(169, 58, 255, 0.9) 53.79%
    ),
    linear-gradient(
      86deg,
      rgba(255, 237, 173, 0.9) 46.43%,
      rgb(255, 255, 229, 0.9) 100%
    );
}

.button-mvp::before {
  content: "";
  position: absolute;
  width: 100px;
  height: 100%;
  background-image: linear-gradient(
    120deg,
    rgba(255, 255, 255, 0) 30%,
    rgba(255, 255, 255, 0.8),
    rgba(255, 255, 255, 0) 70%
  );
  top: 0;
  left: -100px;
  opacity: 0.6;
}

@keyframes shine {
  0% {
    left: -100px;
  }

  60% {
    left: 100%;
  }

  to {
    left: 100%;
  }
}

/* ------------------------------- button-type ------------------------------- */
.button-type {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 10px 25px;

  font-family: "Plus Jakarta Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  color: $silverSonic;
  border-top: 1px solid $greyMetal;
  border-bottom: 1px solid $greyMetal;
  border-right: none;
  border-left: none;
  background: transparent;
  outline: none;

  transition: all 0.1s ease-in-out;

  cursor: pointer;

  span {
    text-align: center;
  }
}

.button-type--first {
  border-left: 1px solid $greyMetal;
  border-radius: 3px 0 0 3px;
}

.button-type--first:hover {
  border-left: 1px solid $blueTotal;
}

.button-type--last {
  border-right: 1px solid $greyMetal;
  border-radius: 0 3px 3px 0;
}

.button-type--last:hover {
  border-right: 1px solid $blueTotal;
}

.button-type--border-left {
  border-left: 1px solid $greyMetal;
}

.button-type--border-left:hover {
  border-left: 1px solid $blueTotal;
}

.button-type:hover {
  color: $white;
  border-top: 1px solid $blueTotal;
  border-bottom: 1px solid $blueTotal;
  background: $blueTotal;

  transition: all 0.1s ease-in-out;
}

/* ------------------------------- button-expose ------------------------------- */
.button-expose {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 8px;
  width: auto;
  height: 48px;
  padding: 12px 24px 12px 19px;

  font-family: "Plus Jakarta Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;

  color: $whiteSnow;
  background: $blueTotal;
  border-radius: 4px;
  border: none;
  outline: none;

  transition: all 0.2s ease-in-out;

  cursor: pointer;
}

/* ------------------------------- button-skip ------------------------------- */
.button-skip {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: auto;
  height: 44px;
  padding: 13px 16px 14px 16px;

  font-family: "Plus Jakarta Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;

  color: $blackRich;
  background: transparent;
  border-radius: 4px;
  appearance: none;
  border: 1px solid $greyMetal;
  outline: none;

  transition: all 0.3s ease-in-out;

  cursor: pointer;
}

.button-onboarding {
  padding: 12px 24px;
  max-width: 700px;
  width: 100%;
  color: $whiteSnow;
  background: $blueTotal;

  &.disabled {
    pointer-events: none;
    color: $greyMetal !important;
    background-color: $whiteNeutrals;
  }
}

.button-expose--disabled {
  color: $greyMetal;
  background: $greySoft;

  cursor: not-allowed;
  pointer-events: none;

  mat-icon {
    svg path {
      fill: $greyMetal;
    }
  }
}

.button-expose--grey {
  padding: 24px;

  color: $blackRich;
  background: $greySoft;
}

.button-status {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 48px;
  padding: 12px 24px 12px 19px;

  font-family: "Plus Jakarta Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;

  color: $greenStrong;

  background-color: $greenLight;
  outline: none;
  border-radius: 4px;
  border: none;
  transition: all 0.1s ease-in-out;

  cursor: pointer;

  @include respond-to("sm") {
    width: 216px;
  }

  &.button-status--pending {
    background-color: $yellowSoft;
    color: $yellowStrong;
  }

  &.button-status--error {
    background-color: $redSoft;
    color: #dd2e16;
  }
  &.button-status--gray {
    background-color: $greySoft;
    color: $silverSonic;
  }
}

.button-with-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 48px;
  padding: 12px 24px 12px 19px;

  font-family: "Plus Jakarta Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;

  color: #fdfdfd;

  background-color: #2d00f7;
  outline: none;
  border-radius: 4px;
  border: none;
  transition: all 0.1s ease-in-out;

  cursor: pointer;

  @include respond-to("sm") {
    width: 216px;
  }
}
.button-dashboard {
  //width: 232px;
  justify-content: center;

  mat-icon {
    //margin-left: 40% !important;
    margin-right: 8px;
  }

  &.disabled {
    pointer-events: none;
    color: $greyMetal !important;
    background-color: $whiteNeutrals;
  }

  &:hover {
    background-color: rgba(#2d00f7, 0.8);
  }
}

.button-with-icon--gray {
  background-color: $greySoft;
  color: $blackRich;
  &:hover {
    background-color: $greyMetal;
  }
}

// .button-secondary {
//   width: 94px;
//   height: 48px;
//   display: inline-flex;
//   padding: 12px 24px;
//   flex-direction: column;
//   justify-content: center;
//   align-items: center;
//   border-radius: 4px;
//   background-color: $greySoft;
//   margin-right: 16px;
//   border: none;

//   cursor: pointer;

//   &:hover {
//     background-color: rgba(#ebedef, 0.9);
//   }
// }

.summary-page_gen-btn {
  color: var(--neutrals-snow-white, #fdfdfd);
  text-align: center;

  font-family: Plus Jakarta Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  display: inline-flex;
  padding: 12px 24px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background: var(--primary-total-blue, #2d00f7);
  cursor: pointer;

  @include respond-to("sm") {
    display: flex;
    width: 100%;
    max-width: 320px;
    padding: 12px 24px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  @include respond-to("md") {
    display: flex;
    width: 320px;
    padding: 12px 24px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

/* ------------------------------- btn-doc ------------------------------- */
.btn-doc {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 100%;
  height: 48px;
  padding: 12px 24px;

  font-family: "Plus Jakarta Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;

  color: $blackRich;
  background: transparent;
  border: 1px solid $greySoft;
  border-radius: 4px;
  appearance: none;
  outline: none;

  transition: all 0.2s ease-in-out;

  cursor: pointer;
}

.btn-doc:hover {
  background-color: $greySoft;

  transition: all 0.2s ease-in-out;
}

/* --------------------------------- Buttons -------------------------------- */

.button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 12px 24px;

  font-family: "Plus Jakarta Sans", sans-serif;
  font-size: 14px;

  font-weight: 600;
  line-height: 24px;

  cursor: pointer;
  outline: none;
  border: none;
  border-radius: 4px;

  transition: background-color 0.08s ease-in-out;

  .mat-icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    margin-right: 8px;
    margin-left: -5px;
    flex-shrink: 0;
    overflow: hidden;

    path {
      fill: $white;
    }

    & + span {
      text-align: left;
    }
  }

  span {
    flex-grow: 1;

    & + .mat-icon {
      margin-left: 8px;
      margin-right: -5px;
    }
  }

  &.disabled {
    pointer-events: none;
    opacity: 0.7;
  }
}

.button--primary {
  color: $white;
  background-color: $blueTotal;

  &:hover {
    background-color: $blueDeep;
  }

  &:disabled {
    background-color: $greySoft;
    color: $greyMetal;
    cursor: not-allowed;

    .mat-icon {
      path {
        fill: $greyMetal;
      }
    }
  }
}

.button--secondary {
  background-color: $greySoft;

  .mat-icon {
    path {
      fill: $blackRich;
    }
  }

  &:hover {
    background-color: $greyMetal;
  }

  &:disabled {
    background-color: $greySoft;
    color: $greyMetal;
    cursor: not-allowed;

    .mat-icon {
      path {
        fill: $greyMetal;
      }
    }
  }
}

.button--ghost {
  background-color: $white;
  border: 1px solid $greySoft;
  padding: 11px 23px;

  .mat-icon {
    path {
      fill: $blackRich;
    }
  }

  &:hover {
    background-color: $greySoft;
  }

  &:disabled {
    background-color: $white;
    color: $greyMetal;
    cursor: not-allowed;

    .mat-icon {
      path {
        fill: $greyMetal;
      }
    }
  }
}

.button--delete {
  color: $redStrong;
  background-color: $redSoft;

  .mat-icon {
    path {
      fill: $redStrong;
    }
  }

  &:hover {
    background-color: $redStrong;
    color: $white;

    .mat-icon {
      path {
        fill: $white;
      }
    }
  }
}

.app-button {
  width: 48px;
  height: 48px;
  border-radius: 4px;
  padding: 9px;
  overflow: hidden;

  display: flex;
  align-items: center;
  justify-content: center;

  transition: background-color 0.08s ease-in-out;

  cursor: pointer;

  &.app-button--small {
    padding: 0;
    width: 24px;
    height: 24px;
  }

  .mat-icon {
    width: 100%;
    height: 100%;

    path {
      fill: $silverSonic;
    }
  }

  &:hover {
    background-color: $greySoft;

    .mat-icon {
      path {
        fill: $blackRich;
      }
    }
  }
}

/* --------------------------------- Buttons small -------------------------------- */

.button-small {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: auto;
  height: 40px;
  padding: 11px 16px;

  font-family: "Plus Jakarta Sans", sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;

  border: none;
  border-radius: 4px;
  outline: none;
  cursor: pointer;

  transition: all 0.08s ease-in-out;
}

.button-small--primary {
  color: $whiteSnow;
  background-color: $blueTotal;

  &:hover {
    background-color: $blueDeep;
  }

  &:disabled {
    color: $greyMetal;
    background-color: $greySoft;

    pointer-events: none;
  }
}

.button-small--secondary {
  color: $blackRich;
  background-color: $greySoft;

  &:hover {
    background-color: $greyMetal;
  }

  &:disabled {
    color: $greyMetal;
    background-color: $greySoft;

    pointer-events: none;
  }
}

.button-small--ghost {
  color: $blackRich;
  background-color: transparent;
  border: 1px solid $greySoft;

  &:hover {
    background-color: $greySoft;
  }

  &:disabled {
    color: $greyMetal;
    background-color: transparent;

    pointer-events: none;
  }
}
