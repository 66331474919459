.content-wrapper {
  color: $blackRich;
  padding: 40px;

  &--height {
    min-height: 80vh;

    @include respond-to("md") {
      height: auto;
    }
  }

  &.content-wrapper--set-height {
    //height: 85vh;
    height: auto;
    @include respond-to("md") {
      height: auto;
    }
}

  @include respond-to("md") {
    padding: 20px;
  }

  &.content-wrapper__subscriptions {
    margin-left: auto;
    margin-right: auto;

    @include respond-to("md") {
      padding-left: 45px;
      padding-right: 45px;
    }

    @include respond-to("sm") {
      padding-left: 32px;
      padding-right: 32px;
      margin-left: 0;
      margin-right: 0;
    }
  }
}

.content-wrapper--center {
  width: 100%;
  max-width: 1356px;
  padding: 40px 128px;
  margin: 0 auto;

  @include respond-to("md") {
    padding: 20px;
  }
}

#appContent {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
 }
