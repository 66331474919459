/* ------------------------------- header-expose ------------------------------- */
.header-expose {
  display: none;

  @include respond-to("md") {
    display: block;
  }

  .header-document__mobile {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
  }

  .header-document__return-btn {
    color: $blueTotal;
    text-decoration: underline;
    cursor: pointer;
  }

  .header-document__control-btns {
    display: flex;
    flex-direction: row;
    gap: 16px;
  }

  .header-expose__container {
    display: grid;
    grid-template-columns: auto 1fr auto;
    align-items: center;
    gap: 10px;
    padding: 20px 20px;

    border-bottom: 1px solid $greySoft;
  }

  .header-expose__icon {
    cursor: pointer;

    mat-icon {
      display: flex;
    }
  }

  .header-expose__title {
  }

  .header-expose__page {
    color: $silverSonic;
  }
}

/* ------------------------------- header-onboarding ------------------------------- */
.header-onboarding {
  position: fixed;
  top: 0;

  width: 100%;
  height: 100px;
  padding: 30px 40px;

  background-color: $white;

  @include respond-to("md") {
    height: 84px;
    padding: 20px;
  }

  .header-onboarding__container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .header-onboarding__title {
  }

  .header-onboarding__wrapper {
    display: flex;
    flex-direction: row;
    gap: 25px;
  }

  .header-onboarding__btn {
  }

  &.shadow {
    z-index: 1;

    box-shadow: 0 4px 10px rgba($black, 0.04);
  }
}

/* ------------------------- App sidebar navigation ------------------------- */

#appSidebar {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 300px;
  overflow-x: hidden;
  overflow-y: auto;

  background-color: $bg-color;
  border-right: 1px solid $greySoft;

  @include respond-to("md") {
    display: none;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background-color: $greySoft;
  }

  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    background-color: transparent;
  }

  &.app-sidebar--collapsed {
    width: 130px;

    .navigation-sidebar__link {
      span {
        display: none;
      }
    }
  }
}

.navigation-sidebar {
  padding: 40px;
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;

  .navigation-sidebar__controls {
    margin-bottom: 40px;
    padding-left: 12px;
  }

  .navigation-sidebar__links {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .navigation-sidebar__link {
      min-width: 48px;
      border-radius: 4px;
      a {
        display: flex;
        align-items: center;
        gap: 10px;
        color: $blackRich;
        padding: 12px;
      }
      i {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 24px;
        height: 24px;
      }
      path {
        fill: $blackRich;
      }

      span {
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
      }

      &.active {
        a {
          color: $blueTotal;
        }
        path {
          fill: $blueTotal;
        }
      }

      &:hover {
        background-color: $greySoft;
      }
    }
  }

  .navigation-sidebar__links--bottom {
    padding-top: 64px;
    margin-top: auto;
  }
}

/* --------------------- App alt navigation (back\close) -------------------- */

#appNavigationAlt {
  display: flex;
  @include respond-to("md") {
    display: none;
  }

  .navigation__back-button {
    position: absolute;
    top: 40px;
    left: 40px;
  }
  .navigation__close-button {
    position: absolute;
    top: 40px;
    right: 40px;

    &.navigation__close-button--summary {
      right: 100px;
    }
  }
}

/* -------------------------- App mobile navigation ------------------------- */

#appNavigationMobile {
  display: none;
  @include respond-to("md") {
    display: flex;
  }
}

.navigation-mobile {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 66px;
  background-color: $white;
  z-index: 100;

  padding: 10px 20px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid $greySoft;

  &__title {
  }

  .navigation-mobile__controls {
    display: inline-flex;
    align-items: center;
    gap: 20px;
    flex-shrink: 0;
    margin-left: auto;

    &.navigation-mobile__controls--expose {
      width: 100%;
      justify-content: space-between;
    }

    .notification {
      position: relative;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      .notification__icon {
        width: 30px;
        height: 30px;
        .mat-icon {
          width: 100%;
          height: 100%;
        }
      }

      .notification__count {
        position: absolute;
        top: -4px;
        right: -3px;
        background-color: $blue;
        border-radius: 50%;
        overflow: hidden;
        width: 18px;
        height: 18px;
        text-align: center;
        line-height: 16px;
        font-size: 11px;
        border: 1px solid $white;
        color: $blueDeep;
      }
    }

    .profile {
      width: 44px;
      height: 44px;
      border-radius: 50%;
      overflow: hidden;
      display: inline-flex;
      align-items: center;
      justify-content: center;

      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      color: $white;
      background-color: $blueTotal;

      cursor: pointer;

      &.profile__balance {
        color: $silverSonic !important;
        background-color: $greySoft !important;
      }
    }
  }

  .navigation-mobile__wrapper {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .navigation-mobile__back-link {
    display: inline-flex;
    align-items: center;

    .link__icon {
      width: 24px;
      height: 24px;
      margin-right: 10px;

      path {
        fill: $blueTotal;
      }
    }

    .link__title {
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      color: $blackRich;
    }
  }
}
.navigation-sidebar__links--mobile {
  display: flex;
  flex-direction: column;
  gap: 6px;

  .navigation-sidebar__link {
    min-width: 48px;
    border-radius: 4px;
    a {
      display: flex;
      align-items: center;
      gap: 10px;
      color: $blackRich;
      padding: 12px;
    }
    i {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 24px;
      height: 24px;
    }
    path {
      fill: $blackRich;
    }

    span {
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
    }

    &.active {
      a {
        color: $blueTotal;
      }
      path {
        fill: $blueTotal;
      }
    }

    &:hover {
      background-color: $greySoft;
    }
  }
}

/* ----------------------------- Tab navigation ----------------------------- */

.tab-navigation {
  margin-bottom: 40px;
  @include respond-to("md") {
    margin-bottom: 20px;
  }
  .tab-row {
    display: flex;
    align-items: stretch;
    gap: 16px;

    border-bottom: 2px solid $greySoft;

    .tab-link {
      padding: 10px;
      font-size: 14px;

      font-weight: 600;
      line-height: 24px;
      color: $silverSonic;
      position: relative;
      border-radius: 4px;

      transition: background-color 0.08s ease-in-out;

      &:hover {
        background-color: $greySoft;
      }
      &.active {
        color: $blackRich;

        &::after {
          position: absolute;
          content: "";
          width: 100%;
          height: 2px;
          bottom: -2px;
          left: 0;
          background-color: $blueTotal;
        }
      }
    }
  }
}


.mob-header-doc{
    display: flex;
    gap: 10px;
}