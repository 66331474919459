.modal-wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: 100%;

  &.modal-wrapper--10gap {
    gap: 10px;
  }
  &.modal-wrapper--24gap {
    gap: 24px;
  }

  @include respond-to("sm") {
    max-width: 250px;
  }

  &.modal-wrapper__subscriptions {
    gap: 40px;

    @include respond-to("sm") {
      max-width: 100%;
    }
  }
}

.modal-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.modal-content {
  display: flex;
  width: 522px;
  align-items: flex-start;
  align-content: flex-start;
  gap: 8px;
  flex-wrap: wrap;
  @include respond-to("sm") {
    width: 250px;
  }
}
.modal-content--small {
  display: flex;
  width: 280px;
  align-items: flex-start;
  align-content: flex-start;
  gap: 8px;
  flex-wrap: wrap;
  overflow-x: hidden;
  overflow-y: hidden;
}
.modal-content--nowrap {
  flex-wrap: nowrap;
  width: auto;

  //@include respond-to("md") {
  //  width: auto;
  //
  //  flex-wrap: wrap;
  //}

  @include respond-to("sm") {
    width: fit-content;

    flex-wrap: wrap;
  }

  &.modal-content--nowrap__scroll {
    overflow-y: auto;
  }
}

.modal__title {
  margin-left: 0;

  @include respond-to("sm") {
    margin-left: 0;
  }
}

.modal-content__card {
  display: flex;
  padding: 8px;
  height: 34px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border: 1px solid #b7b8be;
  background: #fdfdfd;
  color: #757780;
  cursor: pointer;

  &.active,
  &:hover {
    border-radius: 4px;
    //border: #B7B8BE;
    background-color: #ebedef;
  }
}

.modal-back {
  display: flex;
  flex-direction: row;
  gap: 12px;

  .modal-back__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 12px;
    width: 100%;
  }

  .modal-back__icon {
    img {
      width: 11px;
      height: 19px;
    }

    &.disabled {
      opacity: 0.6;
      pointer-events: none;
    }
  }

  .modal-back__title:hover {
  }

  .modal-back__step {
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    color: rgba(#ffffff, 0.6);
  }
}
.mat-menu-wide {
  max-width: 420px !important;
}

.modal-content__left-settings {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: 10px;
  //justify-content: space-between;
  height: 100%;
  gap: 10px;

  .modal-content__select {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
  }

  .modal-content__left-settings-header {
    font-size: 20px;
    font-weight: 600;
    font-family: "Plus Jakarta Sans", sans-serif;
    color: $black;
    cursor: pointer;

    &.selected {
      color: $blueTotal;
    }
  }
}

.modal-content__settings {
  display: flex;
  width: 320px;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  border-radius: 8px;
  background: #ebedef;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

  @include respond-to("sm") {
    width: 250px;
  }
  @include respond-to("lg") {
    width: 250px;
  }
}

.modal-content__settings-image {
  height: 192px;
  object-fit: cover;
  margin-left: auto;
  margin-right: auto;
  border-radius: 4px;
  width: 100%;
}

.modal-content__notification {
  height: auto;
  width: 273px;
}

.modal-content__main-area {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  overflow: hidden;
  width: 100%;

  @include respond-to("sm") {
    margin-top: 100px;
  }

  .modal-content__main-area-header {
    font-size: 20px;
    font-weight: 600;
    font-family: "Plus Jakarta Sans", sans-serif;
    color: $black;
    height: 24px;
    display: flex;
    justify-content: flex-start;
    width: 100%;

    @include respond-to('md') {
      font-size: 16px;
    }

    @include respond-to('sm') {
      margin-bottom: 10px;
    }
  }
}

.modal-content__main-image {
  //max-width: 960px;
  max-height: 640px;
  flex-shrink: 0;
  //object-fit: cover;
  width: 100%;
  height: calc(90vh - 236px);
  position: relative;

  @include respond-to("sm") {
    width: auto;
  }

  img {
    //max-width: 960px;
    max-height: 640px;
    flex-shrink: 0;
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: 8px;

    @include respond-to("sm") {
      width: 250px;
    }
  }

  .modal-content__loader {
    position: absolute;
    left: 40%;
    top: 40%;
  }

  .modal-content__download {
    position: absolute;
    left: 50%;
    top: 85%;
    transform: translate(-50%, -50%);
  }
}

@keyframes shine {
  to {
    background-position-x: -200%;
  }
}

.carousel {
  position: relative;
  width: 100%;

  @include respond-to('sm') {
    width: 250px;
  }
}

.carousel .thumbs-wrapper {
  margin: 0;
  overflow-x: auto;
  overflow-y: hidden;

  &.thumbs-wrapper--no-loader {
    overflow-x: hidden;
  }

  &::-webkit-scrollbar {
    height: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #7458fa;
    border-radius: 4px;
    //width: 30px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 4px;
    background-color: $greySoft;
    border: 1px solid rgba($silverSonic, 1);
  }
}


.carousel .thumbs {
  //display: flex;
  justify-content: flex-start !important;
  transition: all .15s ease-in;
  transform: translateZ(0);
  position: relative;
  list-style: none;
  white-space: nowrap;
}
.carousel .thumb {
  transition: border .15s ease-in;
  display: inline-block;
  margin-right: 6px;
  white-space: nowrap;
  overflow: hidden;
  padding: 2px;
  max-width: 182px;
  max-height: 108px;
  object-fit: cover;
  //margin-right: 10px;
  border: transparent;
  opacity: 0.4;

  &.thumb--loading {
    border-radius: 8px;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
    background: linear-gradient(110deg, #d9d9d9 8%, #d0cfcf 18%, #d9d9d9 33%);
    background-size: 200% 100%;
    animation: 1s shine linear infinite;
    width: 182px;
    height: 108px;
  }


  img {
    width: 100%;
    height: 108px;
    object-fit: cover;
    border-radius: 8px;
  }
  &.selected {
    opacity: 1;
  }
}
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.modal-content__images {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  overflow-x: auto;
  height: 108px;
  //width: 100%;
  overflow-y: hidden;
  margin-right: auto;

  &::-webkit-scrollbar-thumb {
    display: none;
  }

  &::-webkit-scrollbar {
    display: none;
  }
  //@include respond-to("sm") {
  //}
}
.modal-content__image {
  max-width: 182px;
  max-height: 108px;
  object-fit: cover;
  margin-right: 10px;
  opacity: 0.4;
  &.selected {
    opacity: 1;
  }

  &.modal-content__image--loading {
    border-radius: 8px;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
    background: linear-gradient(110deg, #d9d9d9 8%, #d0cfcf 18%, #d9d9d9 33%);
    background-size: 200% 100%;
    animation: 1s shine linear infinite;
    width: 182px;
    height: 108px;
  }

  img {
    max-width: 182px;
    max-height: 108px;
    object-fit: cover;
    border-radius: 8px;
  }
}

/* ------------------------------- modal-cancel ------------------------------- */

.modal-cancel {
  .modal-cancel__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 30px;
    height: 100%;
    padding: 20px;
  }

  .modal-cancel__btn-group {
    display: flex;
    gap: 10px;
  }
}

.modal-info {
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: center;
  bottom: 50px;

  @include respond-to('sm') {
    width: 90%;
  }
}

.modal-info__inner {
  padding: 10px;
  border-radius: 8px;
  background-color: #333333;
  color: $white;
  width: 500px;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
}
