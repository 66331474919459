/* ------------------------------- header-setting ------------------------------- */
.header-setting {
  .header-setting__container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 24px;
    margin-left: 300px;
    padding: 35px 40px;

    @include respond-to("md") {
      margin-left: 0;
      padding: 20px 20px;
    }

    @include respond-to("sm") {
      padding: 0 0 10px;
    }
  }

  .header-setting__form {
    max-width: 800px;
    width: 100%;
  }

  .header-setting__info {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 24px;

    @include respond-to("md") {
      display: none;
    }
  }

  .header-setting__notifications {
    position: relative;
  }

  .header-setting__count {
    position: absolute;
    top: -4px;
    right: -3px;

    width: 18px;
    height: 18px;

    background-color: $white;
    border-radius: 50%;

    span {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 16px;
      height: 16px;
      margin: 1.2px;
      padding: 2px;

      font-family: "Plus Jakarta Sans", sans-serif;
      font-size: 11px;
      font-style: normal;
      font-weight: 500;
      line-height: 14px;

      background-color: $blue;
      border-radius: 50%;
      color: $blueDeep;
    }
  }

  .header-setting__language {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 44px;
    height: 44px;
    padding: 4px;

    font-family: "Plus Jakarta Sans", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 26px;

    background-color: $blueTotal;
    border-radius: 50%;
    color: $whiteSnow;
  }
}

/* ------------------------------- App header ------------------------------- */

.header {
  display: flex;
  align-items: center;
  padding: 35px 40px 0px;
  justify-content: flex-end;

  @include respond-to("md") {
    display: none;
  }

  .header__searchbar {
    width: 100%;
    max-width: 800px;
    position: relative;
    margin-right: auto;

    .searchbar__icon {
      position: absolute;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      top: 50%;
      left: 16px;
      transform: translateY(-50%);
      pointer-events: none;
    }

    .searchbar__input {
      width: 100%;
      height: 54px;
      border: none;
      outline: none;
      border: 1px solid $greySoft;
      border-radius: 4px;
      padding: 14px 16px 14px 56px;
      font-size: 16px;
      line-height: 26px;
      font-family: "Plus Jakarta Sans", sans-serif;

      &::placeholder {
        color: $greyMetal;
        font-size: 16px;
        font-weight: 500;
        line-height: 26px;
      }

      &:hover {
        background-color: $greySoft;
      }

      &:focus {
        border-color: $blueTotal;
        background-color: $white;
      }
    }
  }
  .header__controls {
    display: inline-flex;
    align-items: center;
    gap: 24px;
    flex-shrink: 0;
    margin-left: 24px;

    .notification {
      position: relative;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      .notification__icon {
        width: 30px;
        height: 30px;
        .mat-icon {
          width: 100%;
          height: 100%;
        }
      }

      .notification__count {
        position: absolute;
        top: -4px;
        right: -3px;
        background-color: $blue;
        border-radius: 50%;
        overflow: hidden;
        width: 18px;
        height: 18px;
        text-align: center;
        line-height: 16px;
        font-size: 11px;
        border: 1px solid $white;
        color: $blueDeep;
      }
    }

    .profile {
      width: 44px;
      height: 44px;
      border-radius: 50%;
      overflow: hidden;
      display: inline-flex;
      align-items: center;
      justify-content: center;

      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      color: $white;
      background-color: $blueTotal;

      cursor: pointer;

      &.profile__balance {
        color: $silverSonic;
        background-color: $greySoft;
      }
    }
  }
}
.profile-modal {
  padding: 24px 32px;
  display: flex;
  flex-direction: column;
  font-family: "Plus Jakarta Sans", sans-serif;
  color: $blackRich;
  min-width: 340px;

  .profile-modal__row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 10px;
    align-items: center;
    cursor: pointer;
  }

  .profile-modal__user {
    display: flex;
    align-items: center;
    margin-bottom: 24px;

    .user__avatar {
      span {
        width: 44px;
        height: 44px;
        border-radius: 50%;
        overflow: hidden;
        display: inline-flex;
        align-items: center;
        justify-content: center;

        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        color: $white;
        background-color: $blueTotal;
      }

      img {
        width: 44px;
        height: 44px;
        border-radius: 50%;
        overflow: hidden;
        object-fit: cover;
      }

      &.user__avatar--grey {
        span {
          color: $silverSonic;
          background-color: $greySoft;
        }
      }
    }

    .user__info {
      padding-left: 8px;
      h3 {
        font-size: 14px;
        font-weight: 600;
        line-height: 24px;
      }
      p {
        font-size: 13px;
        line-height: 18px;
        color: $silverSonic;
      }
    }

    .user__links {
      display: flex;
      flex-direction: row;
      gap: 11px;

      a {
        color: $blueTotal;
        text-align: center;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
      }
    }
  }
  .profile-modal__menu {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 4px;
    max-height: 500px;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 5px;

      @include respond-to("430px") {
        width: initial;
      }
    }

    &::-webkit-scrollbar-thumb {
      background-color: $greyMetal;
      border: 1px solid rgba($white, 0.1);
    }

    &::-webkit-scrollbar-track {
      border-radius: 4px;
      background-color: $greySoft;
    }

    li {
      padding: 4px;
      font-size: 14px;
      font-weight: 500;
      line-height: 24px;
      border-radius: 4px;
      transition: background-color 0.08s ease-in-out;

      cursor: pointer;

      &:hover {
        background-color: $whiteNeutrals;
      }
    }

    .profile-modal__link {
      text-decoration-line: underline;
      color: $blueTotal;
      cursor: pointer;
    }
  }
}
